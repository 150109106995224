import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/Services/User/user.service';
import { Auth0Service } from 'src/app/Services/Auth0/auth0.service';

@Component({
  selector: 'app-rater-survey-data-downloader',
  templateUrl: './rater-survey-data-downloader.component.html',
  styleUrls: ['./rater-survey-data-downloader.component.css']
})
export class RaterSurveyDataDownloaderComponent implements OnInit 
{

  constructor(public serviceAUTH: Auth0Service, public serviceUSER: UserService) { }

  ngOnInit() 
  {
  }

  OnDownloadRaterSurveyData()
  {
    this.serviceUSER.DownloadRaterSurveyData();
  }

}
