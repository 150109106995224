import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { PredictionUploadComponent } from 'src/app/Widgets/prediction-upload/prediction-upload.component';
import { Auth0Service } from 'src/app/Services/Auth0/auth0.service';

@Component({
  selector: 'app-prediction-physician',
  templateUrl: './prediction-physician.component.html',
  styleUrls: ['./prediction-physician.component.css']
})
export class PredictionPhysicianComponent implements OnInit
{
  public ref_modal_upload: BsModalRef;

  constructor(private serviceModal: BsModalService, public serviceAUTH: Auth0Service)
  {

  }

  ngOnInit()
  {

  }

  OnUploadNewPatient()
  {
    if (!this.serviceAUTH.IsAuthenticated())
      this.serviceAUTH.Login();

    this.ref_modal_upload = this.serviceModal.show(PredictionUploadComponent, {class: 'modal-lg'});
    // this.ref_modal_upload.content.OnResize(null)

  }

}
