import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Auth0Service } from '../../Services/Auth0/auth0.service';
import { UserService } from '../../Services/User/user.service';
import { MeshService } from 'src/app/Services/Mesh/mesh.service';
import { Interface_InfoUser_Basic, Interface_InfoUser_Address } from 'src/app/Models/InfoUser';


@Component({
  selector: 'app-mesh-panel-admin',
  templateUrl: './mesh-panel-admin.component.html',
  styleUrls: ['./mesh-panel-admin.component.css']
})
export class MeshPanelAdminComponent implements OnInit 
{

  constructor(public router: Router, public serviceAuth: Auth0Service, public serviceUser: UserService, public serviceMesh: MeshService)   
  {

  }

  ngOnInit() 
  {
    
  }

}
