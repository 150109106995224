export const CRANIORATE_FRONTEND_VERSION = "v0.7.9a";

export const DEV_MODE: boolean = true;
export const TEST_DEPLOY: boolean = true;

export const INVALID_ID: number = -2147483648;

export const INVALID_INDEX: number = -127;

export const INVALID_DIRECTORY_NAME: string = "$invalid=directory?name;";

export const QUESTION_SEVERITY_DEFAULT: number = -9235799;

export const ADMIN_PATCH_ID: string = "ADMIN_PATCH";

export const QUESTION_OPERATION_DEFAULT: string = "UNANSWERED";

export const QUESTION_OPERATION_YES: string = "Yes";

export const QUESTION_OPERATION_NO: string = "No";

export const TYPE_USER_ADMIN: string = "USER_ADMIN";

export const TYPE_USER_RATER: string = "USER_RATER";

export const CODE_ERROR_USER_NOT_EXIST: string = "5151";

export const ACCESS_CODE_PARIS_TEMP: string = "";

export const ACCESS_CODE_DEFAULT: string = "AC_DEFAULT";

export const QUESTION_SURVEY_UNANSWERED: string = "UNANSWERED_SURVEY";


export const CURRENT_WORKING_INSTITUTION_UNDEFINED: string = "CURRENT_WORKING_INSTITUTION_UNDEFINED";
export const CURRENT_FIRSTNAME_UNDEFINED: string = "CURRENT_FIRSTNAME_UNDEFINED";
export const CURRENT_LASTNAME_UNDEFINED: string = "CURRENT_LASTNAME_UNDEFINED";


export const DIAGNOSIS_PREDICTION_UNDEFINED: string = "PREDICTION_DIAGNOSIS_UNDEFINED";

export const DATE_BIRTH_PREDICTION_UNDEFINED: string = "PREDICTION_DATE_BIRTH_UNDEFINED";

export const DATE_SCAN_PREDICTION_UNDEFINED: string = "PREDICTION_DATE_SCAN_UNDEFINED";

export const AGE_SCAN_PREDICTION_UNDEFINED: string = "PREDICTION_AGE_SCAN_UNDEFINED";

export const GENDER_PREDICTION_UNDEFINED: string = "PREDICTION_GENDER_UNDEFINED";

export const RACE_PREDICTION_UNDEFINED: string = "PREDICTION_RACE_UNDEFINED";

export const COMMENTS_PREDICTION_UNDEFINED: string = "PREDICTION_COMMENTS_UNDEFINED";



export const PPCA_MAHALANOBIS_ABOVE_METOPIC_5SIGMA: boolean = false;

export const METOPIC_SEVERITY_SCORE_PREDICTION_UNDEFINED: number = -934604857;

export const METOPIC_SEVERITY_PERCENTILE_PREDICTION_UNDEFINED: number = -934604857;

export const META_DATA_PREDICTION_UNDEFINED: string = "PREDICTION_META_DATA_UNDEFINED";


export const RESULT_PREDICTION_UNDEFINED: number = -934604857;

export const TIME_SHOWING_SUCCESS_ALERT: number = 3000;

export const MESH_DEFAULT_ANGEL_Z = -0.54;
export const MESH_DEFAULT_ANGEL_X = -1.42;

export const DICOM_MAX_SLICE_THICKNESS = 2.3;

export const STATUS_PREDICTION_UNDEFINED: string = "PREDICTION_UNDEFINED";
export const STATUS_PREDICTION_UPLOADED: string = "PREDICTION_UPLOADED";
export const STATUS_PREDICTION_IN_PROGRESS: string = "PREDICTION_IN_PROGRESS";
export const STATUS_PREDICTION_ERROR: string = "PREDICTION_ERROR";
export const STATUS_PREDICTION_FINISHED: string = "PREDICTION_FINISHED";
export const STATUS_PREDICTION_VISUALIZED: string = "PREDICTION_VISUALIZED";
export const STATUS_PREDICTION_REVIEWED: string = "PREDICTION_REVIEWED";
export const STATUS_PREDICTION_DELETED: string = "PREDICTION_DELETED";
