import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as DEFINES from '../../Configurations/Defines';
import { Auth0Service } from '../../Services/Auth0/auth0.service';
import { UserService } from 'src/app/Services/User/user.service';
import { MeshService } from 'src/app/Services/Mesh/mesh.service';
import { Interface_InfoUser_Basic } from 'src/app/Models/InfoUser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RaterSurveyModalComponent } from '../rater-survey-modal/rater-survey-modal.component';
import { UserAgreementModalComponent } from '../user-agreement-modal/user-agreement-modal.component';
import { HttpClient } from '@angular/common/http';
import { CONFIG_SERVER } from '../../Configurations/Config_Server';
import { DEV_MODE, TEST_DEPLOY } from '../../Configurations/Defines';


@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {

  imgLogoPath = "assets/logo.png"
  isCollapsed = true;
  constructor(public router: Router, public serviceAuth: Auth0Service, public serviceUser: UserService, public serviceMesh: MeshService, private serviceNgModal: NgbModal, private httpClient: HttpClient)
  {
    
  }

  ngOnInit()
  {
  }

  public OnCollapse()
  {
    this.isCollapsed = !this.isCollapsed;
    return this.isCollapsed;
  }

  public ShowUserProfile()
  {
    return this.serviceAuth.IsAuthenticated() && this.serviceUser.isForbidden == false;
  }

  public ShowUserAgreement()
  {
    return this.serviceAuth.IsAuthenticated() && this.serviceUser.isForbidden == true;
  }

  public ShowRaterSurvey()
  {
    return this.serviceAuth.IsAuthenticated() && this.serviceUser.isForbidden == false;
  }

  public ShowMeshRater()
  {
    return this.serviceAuth.IsAuthenticated() && this.serviceUser.isForbidden == false;
  }

  public ShowMeshPanel()
  {
    if(this.serviceAuth.IsAuthenticated() && this.serviceUser.isForbidden == false)
    {
      if(this.serviceUser.info.type_account == DEFINES.TYPE_USER_ADMIN)
      {
        return true;
      }
    }

    return false;

  }

  public ShowRefreshButton()
  {
    var isAuthenticated: boolean = this.serviceAuth.IsAuthenticated();

    if(!isAuthenticated)
    {
      return false;
    }

    if(this.serviceUser.info.type_account==undefined || this.serviceUser.info.type_account==null)
    {
      return false;
    }

    return true;

  }

  public ShowLogoutButton()
  {
    return this.serviceAuth.IsAuthenticated();
  }

  public ShowUsername()
  {
    //console.log("navbar.ShowUsername: current username is " + this.serviceAuth.m_username);
    return this.ShowLogoutButton() && this.serviceAuth.m_username != "unknown user";
  }

  public ShowLoginButton(): boolean
  {
    return !this.serviceAuth.IsAuthenticated();
  }

  public ShowPatientDiagnosis(): boolean
  {
    return this.serviceAuth.IsAuthenticated();
  }

  public ShowHelp(): boolean
  {
    return true;
  }  

  public GetUsername(): string
  {
    return this.serviceAuth.m_username;
  }

  public OnRefresh()
  {
    console.log("nav-bar.OnRefresh()");
    this.serviceUser.SetLoadingStatus(true, false);

    this.serviceUser.Send_LoadProfile().subscribe(
      respMessageSuccess=>{
        // console.log("LoadProfile succeeded:");
        // console.log(respMessageSuccess);
        var userInfo: Interface_InfoUser_Basic = JSON.parse(respMessageSuccess);
        this.serviceUser.info = userInfo;
        this.serviceUser.isForbidden = false;
        this.serviceMesh.ReloadPatchInfoFromUserService();
        // alert("Refresh: Successed!");
        
        // this.serviceUser.SetLoadingStatus(false, true);
        this.serviceUser.SetLoadingStatus(false, false);

        // setTimeout(()=>{
        //   this.serviceUser.SetLoadingStatus(false, false);
        // }, DEFINES.TIME_SHOWING_SUCCESS_ALERT);

        /*
        jump to the page of rater survey if it is not submitted
        it was used when collecting ratings
        */
        // if(this.serviceUser.info.rater_survey_info == null || 
        //   this.serviceUser.info.rater_survey_info.is_survey_submitted == undefined ||
        //   this.serviceUser.info.rater_survey_info.is_survey_submitted == null ||
        //   this.serviceUser.info.rater_survey_info.is_survey_submitted==false)
        // {
        //   var modalActive = this.serviceNgModal.open(RaterSurveyModalComponent, { size: 'lg'});
        //   modalActive.componentInstance.infoUser = this.serviceUser.info;
        // }
      },
      respMessageError=>{
        // console.log("LoadProfile failed:");
        // console.log(respMessageError);

        this.serviceUser.SetLoadingStatus(false, false);
        alert("Refresh: Failed!\n"+respMessageError);
      }
    );
  }

  public OnNavLinkHome()
  {
    this.router.navigate(['']);
  }

  public OnNavLinkProfile()
  {
    this.router.navigate(['/profile']);
  }

  public OnNavLinkUserAgreement()
  {
    if(this.serviceUser.isForbidden && 
      (this.serviceUser.info.type_account == undefined || this.serviceUser.info.type_account == null))
    {
      var modalActive = this.serviceNgModal.open(UserAgreementModalComponent);// {windowClass: 'window_modal'});
      modalActive.componentInstance.isPreview = false;
    }
  }

  public OnNavLinkRaterSurvey()
  {
    if(this.serviceUser.info.rater_survey_info == null || 
       this.serviceUser.info.rater_survey_info.is_survey_submitted == undefined ||
       this.serviceUser.info.rater_survey_info.is_survey_submitted == null ||
       this.serviceUser.info.rater_survey_info.is_survey_submitted==false)
    {
      var modalActive = this.serviceNgModal.open(RaterSurveyModalComponent);
      modalActive.componentInstance.infoUser = this.serviceUser.info;
    }
    else
    {
      alert("You have already submitted your Rater Survey!");
    }
  }

  public OnNavLinkMeshRater()
  {
    this.router.navigate(['/mesh-rater']);
  }

  public OnNavLinkMeshPanelAdmin()
  {
    this.router.navigate(['/mesh-panel-admin']);
  }

  public OnNavLinkPatientDiagnosis()
  {
    this.router.navigate(['/prediction-physician']);
  }

  public OnNavLinkHelp()
  {
    this.router.navigate(['/help']);
  }

  public OnNavLinkAbout()
  {
    this.router.navigate(['/about']);
  }

  public OnNavLinkWelcome()
  {
    this.router.navigate(['/welcome']);
  }

  public OnNavLinkTutorials()
  {
    this.router.navigate(['/tutorial']);
  }

  public OnNavLinkUnderstandingResults()
  {
    this.router.navigate(['/understanding-results']);
  }

  public ShowHealthButton(): boolean
  {
    return DEV_MODE || TEST_DEPLOY;
  }  

  public OnNavLinkHealth()
  {
    console.log("nav-bar.OnNavLinkHealth()");
    let url = CONFIG_SERVER.DOMAIN+CONFIG_SERVER.API_USER_HEALTH;
    this.httpClient.get(url, { responseType: 'text' }).subscribe(data => {
      console.log("result: " + data);
    });
  }
}
