import { Routes } from '@angular/router';
import { MainComponent } from './Webpages/main/main.component';
import { CallbackComponent } from './Webpages/callback/callback.component';
import { MeshRaterComponent } from './Webpages/mesh-rater/mesh-rater.component';
import { MeshPanelAdminComponent } from './Webpages/mesh-panel-admin/mesh-panel-admin.component';
import { PredictionPhysicianComponent } from './Webpages/prediction-physician/prediction-physician.component';
import { HelpComponent } from './Webpages/help/help.component';
import { AboutComponent } from './Webpages/about/about.component';
import { TutorialComponent } from './Webpages/tutorial/tutorial.component';
import { UnderstandingResultsComponent } from './Webpages/understanding-results/understanding-results.component';
import { TermsComponent } from './Widgets/terms/terms.component';
import { WelcomeComponent } from './Webpages/welcome/welcome.component';

export const ROUTES: Routes = [
  { path: '', component: MainComponent },
  { path: 'callback', component: CallbackComponent },
  { path: 'welcome', component: WelcomeComponent },
  { path: 'mesh-rater', component: MeshRaterComponent },
  { path: 'mesh-panel-admin', component: MeshPanelAdminComponent},
  { path: 'prediction-physician', component: PredictionPhysicianComponent},
  { path: 'help', component: HelpComponent},
  { path: 'about', component: AboutComponent},
  { path: 'tutorial', component: TutorialComponent},
  { path: 'understanding-results', component: UnderstandingResultsComponent},
  { path: 'terms', component: TermsComponent},
  { path: '**', redirectTo: '' }
];
