import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Interface_InfoUser_Basic, Interface_InfoUser_Address } from 'src/app/Models/InfoUser';
// import { UserService } from '../../Services/User/user.service';


@Component({
  selector: 'app-user-profile-widget',
  templateUrl: './user-profile-widget.component.html',
  styleUrls: ['./user-profile-widget.component.css']
})
export class UserProfileWidgetComponent implements OnInit 
{
  @Input() userInfo: Interface_InfoUser_Basic;
  @Output() eventUserInfoChange = new EventEmitter<Interface_InfoUser_Basic>();
  @Output() eventUserAddressInfoChange = new EventEmitter<Interface_InfoUser_Address>();
  @Output() eventSave = new EventEmitter();

  constructor()
  { 

  }

  ngOnInit() 
  {
  }

  OnChangeFirstName(value) 
  {
    // var dataChanged = new {Key="first_name", Value=value};
    var userChanged: Interface_InfoUser_Basic =
    {
      first_name: value
    }
    
    this.eventUserInfoChange.emit(userChanged);
  }

  OnChangeLastName(value) 
  {
    // var dataChanged = new {Key="first_name", Value=value};
    var userChanged: Interface_InfoUser_Basic =
    {
      last_name: value
    }
    this.eventUserInfoChange.emit(userChanged);
  }

  OnChangeAge(value) 
  {
    // var dataChanged = new {Key="first_name", Value=value};
    var userChanged: Interface_InfoUser_Basic =
    {
      age: value
    }
    this.eventUserInfoChange.emit(userChanged);
  }

  OnChangeDOB(value) 
  {
    // var dataChanged = new {Key="first_name", Value=value};
    var userChanged: Interface_InfoUser_Basic =
    {
      date_of_birth: value
    }
    
    this.eventUserInfoChange.emit(userChanged);
  }

  OnChangeAddressStreet(value) 
  {
    // var dataChanged = new {Key="first_name", Value=value};
    var userChanged: Interface_InfoUser_Address = 
    {
      street: value,
    };
    
    this.eventUserAddressInfoChange.emit(userChanged);
  }

  OnChangeAddressCity(value) 
  {
    // var dataChanged = new {Key="first_name", Value=value};
    var userChanged: Interface_InfoUser_Address = 
    {
      city: value,
    };

    this.eventUserAddressInfoChange.emit(userChanged);
  }

  OnChangeAddressState(value) 
  {
    // var dataChanged = new {Key="first_name", Value=value};
    var userChanged: Interface_InfoUser_Address = 
    {
      state: value,
    };

    this.eventUserAddressInfoChange.emit(userChanged);
  }

  OnChangeAddressZipCode(value) 
  {
    // var dataChanged = new {Key="first_name", Value=value};
    var userChanged: Interface_InfoUser_Address = 
    {
      zip_code: value,
    };
    
    this.eventUserAddressInfoChange.emit(userChanged);
  }

  OnBtnSave()
  {
    this.eventSave.emit();
  }


}
