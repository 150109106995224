import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['../help/help.component.css']
})
export class AboutComponent implements OnInit {

  imageRatingInterface = '../../../assets/Help/rating_interface.png';
  uploadPanel = '../../../assets/Help/upload_panel.png';
  imageHistogram = '../../../assets/Help/histogram.png';
  imagePreprocess = '../../../assets/Help/preprocess.png';
  metopicSpectrum = '../../../assets/Help/metopic_spectrum.png';
  sagMssCmdScatter = '../../../assets/Help/sag_mss_cmd_scatter.png';

  constructor() { }

  ngOnInit() {
  }

}
