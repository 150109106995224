import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { Interface_InfoMesh_Basic, Interface_InfoPatch_Basic } from 'src/app/Models/InfoMesh';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MeshModalComponent } from '../mesh-modal/mesh-modal.component';
import { Auth0Service } from 'src/app/Services/Auth0/auth0.service';
import { MeshService } from 'src/app/Services/Mesh/mesh.service';
import { UserService } from 'src/app/Services/User/user.service';
import { RaterSurveyModalComponent } from '../rater-survey-modal/rater-survey-modal.component';

@Component({
  selector: 'app-mesh-list',
  templateUrl: './mesh-list.component.html',
  styleUrls: ['./mesh-list.component.css']
})
export class MeshListComponent implements OnInit, AfterViewInit 
{
  // @Input() id_patch: string;
  // @Input() listInfo: Interface_InfoMesh_Basic[];
  @Input() infoPatch: Partial<Interface_InfoPatch_Basic>;
  @Input() showPatientID: boolean;
  infoSelected: Partial<Interface_InfoMesh_Basic>;
  public isCollapsed = false;
  // strStyleProgress: string;
  // numProgress: number;

  constructor(public serviceUser: UserService, public serviceAUTH: Auth0Service, public serviceMESH: MeshService, private serviceNgModal: NgbModal)
  { 

  }

  ngOnInit() 
  {

  }

  ngAfterViewInit()
  {
    
  }

  public OnSelect(info)
  {
    if(this.serviceUser.info.rater_survey_info == null || 
       this.serviceUser.info.rater_survey_info.is_survey_submitted == undefined ||
       this.serviceUser.info.rater_survey_info.is_survey_submitted == null ||
       this.serviceUser.info.rater_survey_info.is_survey_submitted == false)
    {
      alert("You have not finished the rater survey!\nClick Ok to finish the rater survey!");
      var modalActive = this.serviceNgModal.open(RaterSurveyModalComponent, { size: 'lg'});
      modalActive.componentInstance.infoUser = this.serviceUser.info;
    }
    else
    {
      this.infoSelected = info;  
      var indexSelected = this.serviceMESH.GetMeshIndex(this.infoSelected)
      var result: boolean = this.serviceMESH.SwitchCurrentMesh(this.infoSelected.id_patch, indexSelected);
      if (result)
      {
        const modalActive = this.serviceNgModal.open(MeshModalComponent);// {windowClass: 'window_modal'});
        // modalActive.componentInstance.idList = this.idList;
        modalActive.componentInstance.infoMesh = this.serviceMESH.info_current;
        modalActive.componentInstance.showPatientID = this.showPatientID;
      }
    }

  }

  public OnSubmit()
  {
    // this.infoPatch.is_submitted = true;
    if(confirm("You will not be able to change any rating in this patch after submitting.\nAre you sure you want to submit this patch?"))
    {
      this.serviceMESH.SubmitPatch(this.infoPatch.id_patch);
    }
    
  }

}
