import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import * as DEFINES from "../../Configurations/Defines";
import { CONFIG_SERVER } from '../../Configurations/Config_Server';
import { Auth0Service } from '../Auth0/auth0.service';
import { Interface_InfoPatch_Basic,
         Interface_InfoMesh_Basic
        //  PATCH_ID_01,
        //  PATCH_ID_02,
        //  PATCH_ID_03,
        //  PATCH_DATA
        } from 'src/app/Models/InfoMesh';
import { UserService } from '../User/user.service';
import { saveAs as importedSaveAs } from "file-saver";


@Injectable({
  providedIn: 'root'
})
export class MeshService 
{
  public isDownloadingMeshData: boolean = false;

  public isSavingRatingData: boolean = false;

  public isSavingRatingDataSuccess: boolean = false;

  public isSubmittingPatch: boolean = false;

  public isSubmittingPatchSuccess: boolean = false;


  public index_current: number = DEFINES.INVALID_INDEX;

  public info_current: Partial<Interface_InfoMesh_Basic> = 
  {

  };

  public map_list_patch: Map<string, Interface_InfoPatch_Basic> = null;

  public map_mesh_data: Map<string, object> = new Map<string, object>();

  constructor(public router: Router, public serviceAuth: Auth0Service, public serviceUser: UserService, private httpClient: HttpClient) 
  { 

  }

  public LoadAllMeshInfo(): boolean
  {
    var get_map_list_patch: Map<string, Interface_InfoPatch_Basic> = this.Send_GetPatch();

    if (get_map_list_patch == null)
    {
      return false;
    }

    this.map_list_patch = get_map_list_patch;
    return true;

  }

  public ReloadPatchInfoFromUserService(): boolean
  {
    var list_patch = this.serviceUser.info.list_patch;

    this.map_list_patch = this.GenerateMapPatch(list_patch);
    this.CalculateAllPatchesProgress();
    return false;
  }

  public GetMeshIndex(infoMesh: Interface_InfoMesh_Basic): number
  {
    if(this.map_list_patch==null)
    {
      return DEFINES.INVALID_INDEX;
    }

    if(!this.map_list_patch.has(infoMesh.id_patch))
    {
      return DEFINES.INVALID_INDEX;
    }

    var list_mesh: Interface_InfoMesh_Basic[] = this.map_list_patch.get(infoMesh.id_patch).list_mesh_info;
    var indexRTN: number = DEFINES.INVALID_INDEX;

    for(var idx=0; idx<list_mesh.length; idx++)
    {
      var infoMeshCurrent: Interface_InfoMesh_Basic = list_mesh[idx];
      if(infoMeshCurrent.id_patch == infoMesh.id_patch && 
         infoMeshCurrent.id_patient== infoMesh.id_patient &&
         infoMeshCurrent.id_key_s3== infoMesh.id_key_s3)
      {
        indexRTN = idx;
        break;
      }
    }

    return indexRTN;
  }

  public SwitchCurrentMesh(id_patch: string, indexMeshNew: number): boolean
  {
    if(!this.CheckListIDandMeshIndex(id_patch, indexMeshNew))
    {
      return false;
    }

    var infoMesh_get: Interface_InfoMesh_Basic = this.map_list_patch.get(id_patch).list_mesh_info[indexMeshNew];
    

    this.info_current = Object.assign({}, infoMesh_get);
    this.index_current = indexMeshNew;
    return true;
  }

  public SaveCurrentMesh(infoMesh: Interface_InfoMesh_Basic):boolean
  {
    if(!this.CheckIsMeshValid(infoMesh))
    {
      return false;
    }

    var indexMesh = this.GetMeshIndex(infoMesh);

    if(this.index_current != indexMesh)
    {
      return false;
    }

    var infoMesh_get: Interface_InfoMesh_Basic = this.map_list_patch.get(infoMesh.id_patch).list_mesh_info[this.index_current];
    var infoMesh_backup: Interface_InfoMesh_Basic = {
      id: infoMesh_get.id,
      id_patch: infoMesh_get.id_patch,
      id_patient: infoMesh_get.id_patient,
      id_key_s3: infoMesh_get.id_key_s3,
      is_saved: infoMesh_get.is_saved,
      type_patient: infoMesh_get.type_patient,
      severity: infoMesh_get.severity,
      operation: infoMesh_get.operation,
      data_mesh: infoMesh_get.data_mesh,
      fileUrl: infoMesh_get.fileUrl,
    }

    if(infoMesh_backup.severity != DEFINES.QUESTION_SEVERITY_DEFAULT &&
       infoMesh.severity != DEFINES.QUESTION_SEVERITY_DEFAULT &&
       infoMesh_backup.severity == infoMesh.severity &&

       infoMesh_backup.operation != DEFINES.QUESTION_OPERATION_DEFAULT &&
       infoMesh.operation != DEFINES.QUESTION_OPERATION_DEFAULT &&
       infoMesh_backup.operation == infoMesh.operation)
    {
      return true;
    }

    infoMesh_get.severity = infoMesh.severity;
    infoMesh_get.operation = infoMesh.operation;
    infoMesh_get.is_saved = true;

    
    
    
    var isUpdatedLocal: boolean = this.serviceUser.UpdateLocalMeshInfo(infoMesh_get);

    if(isUpdatedLocal)
    {
      infoMesh.is_saved = true;

      this.isSavingRatingData = true;
      this.isSavingRatingDataSuccess = false;

      this.Send_UpdateRating(infoMesh_get).subscribe(
      // this.serviceUser.Send_UpdateProfile().subscribe(
        respMessageSuccess=>{
          console.log(respMessageSuccess);
          this.CalculatePatchProgress(infoMesh_get.id_patch);

          

          this.isSavingRatingData = false;
          // this.isSavingRatingDataSuccess = true;
          this.isSavingRatingDataSuccess = false;
          // alert("Rate Mesh: Successed");
          // setTimeout(()=>{
          //   this.isSavingRatingData = false;
          //   this.isSavingRatingDataSuccess = false;
          // }, DEFINES.TIME_SHOWING_SUCCESS_ALERT);

          if(this.map_list_patch.has(infoMesh_get.id_patch))
          {
            var infoPatch: Interface_InfoPatch_Basic = this.map_list_patch.get(infoMesh_get.id_patch);
            if(infoPatch.is_able_to_submitted)
            {
              alert("You have finished rating all of the skulls. Once you are satisfied with your answers, please close this window and click 'Submit'.")
            }
          }
        },
        respMessageError=>{
          console.log(respMessageError);

          var isUpdatedLocal: boolean = this.serviceUser.UpdateLocalMeshInfo(infoMesh_backup);
          infoMesh = infoMesh_backup;

          this.isSavingRatingData = false;
          this.isSavingRatingDataSuccess = false;
          alert("Rate Skull: Failed");
          
  
          
          
        }
      );
    }
    else
    {
      alert("Rate Skull: Local Update Failed");
    }
    


    return true;
  }

  public GetMeshInfo(id_patch: string, indexMesh: number): Interface_InfoMesh_Basic
  {
    if(!this.CheckListIDandMeshIndex(id_patch, indexMesh))
    {
      return null;
    }

    var infoRtn: Interface_InfoMesh_Basic = this.map_list_patch.get(id_patch).list_mesh_info[indexMesh];;

    return infoRtn;
  }

  public Send_DownloadMeshData(infoMesh: Interface_InfoMesh_Basic, callbackLoadFinished): Observable<any>
  {
    if(!this.CheckIsMeshValid(infoMesh))
    {
      return null;
    }
    
    // To Do: Replace with data downloaed from the server
    // this.httpClient.get(infoMesh.fileUrl, {responseType: 'arraybuffer'})
    // .subscribe(data => 
    //   {
    //     console.log(data.byteLength);
    //     infoMesh.data_mesh = data;
    //     callbackLoadFinished(data);
    //     return data;
    //   });

    let url: string = CONFIG_SERVER.DOMAIN+CONFIG_SERVER.API_MESH_DOWNLOAD;
    // let body = JSON.stringify(this.info);
    let idToken = this.serviceAuth.IdToken;
    let paramsIDs = new HttpParams().set('id_token', idToken)
                                    .set('id_patch', infoMesh.id_patch)
                                    .set('id_patient', infoMesh.id_patient)
                                    .set('id_key_s3', infoMesh.id_key_s3);
    
    let accessToken = this.serviceAuth.AccessToken;
    let authorizationValue = "Bearer " + accessToken;
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: authorizationValue//,
      // 'Content-Type': 'application/json'
    });

    const requestOptions: Object = {
      headers,
      params: paramsIDs,
      responseType: "arraybuffer"
    };

    // return this.httpClient.get<string>(url, requestOptions);
    return this.httpClient.get<any>(url, requestOptions);

    return null;
  }

  public SetMeshDownloadingStatus(isDownloading: boolean)
  {
    this.isDownloadingMeshData = isDownloading;
  }

  public LoadMeshDataFromLocal(id_key_s3: string): object
  {
    if(this.map_mesh_data.has(id_key_s3))
    {
      return this.map_mesh_data.get(id_key_s3);
    }

    return null;
  }

  public SaveMeshDataToLocal(id_key_s3: string, data: object)
  {
    if(!this.map_mesh_data.has(id_key_s3))
    {
      this.map_mesh_data.set(id_key_s3, data);
    }
  }

  public Send_UpdateRating(infoMesh: Interface_InfoMesh_Basic): Observable<any>
  {
    var url: string = CONFIG_SERVER.DOMAIN+CONFIG_SERVER.API_RATER_RATE_MESH;

    var idToken = this.serviceAuth.IdToken;
    let paramsIDToken = new HttpParams().set('id_token', idToken);

    // var body: string = JSON.stringify(this.info);
    var body: string = this.ConvertMeshInfoToJsonString(infoMesh);
    var accessToken = this.serviceAuth.AccessToken;
    var authorizationValue = "Bearer " + accessToken;
    var headers: HttpHeaders = new HttpHeaders({
      Authorization: authorizationValue,
      'Content-Type': 'application/json'
    });

    const requestOptions: Object = {
      headers,
      params: paramsIDToken,
      responseType: 'text'
    };

    // return this.httpClient.get<string>(url, requestOptions);
    return this.httpClient.put<any>(url, body, requestOptions);

  }

  private ConvertMeshInfoToJsonString(infoMesh: Interface_InfoMesh_Basic): string
  {
    // JSON()
    var body: string = JSON.stringify(infoMesh, (key, value) => {
      if (value !== null) return value
    });

    return body;
  }

  private CheckListIDandMeshIndex(id_patch: string, indexMesh: number): boolean
  {
    if(this.map_list_patch==null)
    {
      return false;
    }

    if(!this.map_list_patch.has(id_patch))
    {
      return false;
    }

    var list_mesh: Interface_InfoMesh_Basic[] = this.map_list_patch.get(id_patch).list_mesh_info;
    if(indexMesh<0 || indexMesh>=list_mesh.length)
    {
      return false;
    }

    return true;
  }

  Send_GetPatch(): Map<string, Interface_InfoPatch_Basic>//Observable<any>
  {
    var get_map_patch: Map<string, Interface_InfoPatch_Basic> = new Map<string, Interface_InfoPatch_Basic>();
    // get_map_patch.set(PATCH_ID_01, PATCH_DATA[0]);
    // get_map_patch.set(PATCH_ID_02, PATCH_DATA[1]);
    // get_map_patch.set(PATCH_ID_03, PATCH_DATA[2]);

    return get_map_patch;
  }

  private GenerateMapPatch(list_infoPatch: Interface_InfoPatch_Basic[]): Map<string, Interface_InfoPatch_Basic>
  {
    if(list_infoPatch == undefined)
    {
      return null;
    }
    
    var get_map_patch: Map<string, Interface_InfoPatch_Basic> = new Map<string, Interface_InfoPatch_Basic>();
    for(let infoPatch of list_infoPatch)
    {
      get_map_patch.set(infoPatch.id_patch, infoPatch);
    }

    return get_map_patch;
  }

  private CheckIsMeshValid(infoMesh: Interface_InfoMesh_Basic): boolean
  {
    if(infoMesh.id_patch == undefined || infoMesh.id_patient == undefined || infoMesh.id_key_s3 == undefined)
    {
      return false;
    }

    if(infoMesh.id_patch == null || infoMesh.id_patient == null || infoMesh.id_key_s3 == null)
    {
      return false;
    }

    if(!this.map_list_patch.has(infoMesh.id_patch))
    {
      return false;
    }

    var infoPatch: Interface_InfoPatch_Basic = this.map_list_patch.get(infoMesh.id_patch);

    var isValid: boolean = false;
    for(var infoMeshCurrent of infoPatch.list_mesh_info)
    {
      if(infoMeshCurrent.id_patch == infoMesh.id_patch &&
         infoMeshCurrent.id_patient == infoMesh.id_patient &&
         infoMeshCurrent.id_key_s3 == infoMesh.id_key_s3)
        {
          isValid = true;
          break;
        }
    }

    return isValid;
  }

  ab2str(
    input: ArrayBuffer | Uint8Array | Int8Array | Uint16Array | Int16Array | Uint32Array | Int32Array,
    outputEncoding: string = 'utf-8',
  ): string {
    const decoder = new TextDecoder(outputEncoding);
    return decoder.decode(input);
  }
  
  /**
   * Convert String to ArrayBuffer via TextEncoder
   *
   * @see https://developer.mozilla.org/zh-CN/docs/Web/API/TextEncoder
   */
  str2ab(input: string): ArrayBuffer {
    const view = this.str2Uint8Array(input);
    return view.buffer;
  }
  
  /** Convert String to Uint8Array */
  str2Uint8Array(input: string): Uint8Array {
    const encoder = new TextEncoder();
    const view = encoder.encode(input);
    return view;
  }

  public Send_UploadMesh(infoMesh: Interface_InfoMesh_Basic): Observable<any>
  {
    var url: string = CONFIG_SERVER.DOMAIN+CONFIG_SERVER.API_MESH_UPLOAD;

    var idToken = this.serviceAuth.IdToken;
    let paramsIDToken = new HttpParams().set('id_token', idToken)
                                        .set('id_patch', infoMesh.id_patch)
                                        .set('id_patient', infoMesh.id_patient)
                                        .set('type_patient', infoMesh.type_patient);

    // string id_patch_mesh, string id_patient_mesh, 

    // var body: string = JSON.stringify(this.info);

    // var strdata = "";

    // var strdata = new TextDecoder("utf-8").decode(infoMesh.data_mesh);

    // var byteData = new TextEncoder().encode(strdata);

    // var arrData = byteData.buffer;

    // aaaa
    // var strData = this.ab2str(infoMesh.data_mesh);
    // var arrData: ArrayBuffer|SharedArrayBuffer = this.str2ab(strData);

    // console.log(infoMesh.data_mesh.byteLength);
    // console.log(strData.length);

    // // console.log(byteData.length);

    // console.log(arrData.byteLength);



    // console.log(encoded.byteLength);

    // var sss = infoMesh.data_mesh.byteLength;
    // var bytes = new Uint8Array(infoMesh.data_mesh);
    let body = infoMesh.data_mesh;
    // let body = {data: strData};
    var accessToken = this.serviceAuth.AccessToken;
    var authorizationValue = "Bearer " + accessToken;
    var headers: HttpHeaders = new HttpHeaders({
      Authorization: authorizationValue,
      'Content-Type': 'application/octet-stream'
    });

    const requestOptions: Object = {
      headers,
      params: paramsIDToken,
      responseType: 'text'
    };

    // return this.httpClient.get<string>(url, requestOptions);
    return this.httpClient.post<any>(url, body, requestOptions);
  }

























  public Send_UploadMeshV2(infoMesh: Interface_InfoMesh_Basic, file: File): Observable<any>
  {

    let formData: FormData = new FormData();
    formData.append('UploadFile', file, file.name);


    var url: string = CONFIG_SERVER.DOMAIN+CONFIG_SERVER.API_MESH_UPLOAD;

    var idToken = this.serviceAuth.IdToken;
    let paramsIDToken = new HttpParams().set('id_token', idToken)
                                        .set('id_patch', infoMesh.id_patch)
                                        .set('id_patient', infoMesh.id_patient)
                                        .set('type_patient', infoMesh.type_patient);


    var accessToken = this.serviceAuth.AccessToken;
    var authorizationValue = "Bearer " + accessToken;

    var headers: HttpHeaders = new HttpHeaders({
      Authorization: authorizationValue,
      // 'Content-Type': 'multipart/form-data',
      'Accept': 'application/json'
    });

    const requestOptions: Object = {
      headers,
      params: paramsIDToken,
      responseType: 'text'
    };

    // return this.httpClient.get<string>(url, requestOptions);
    return this.httpClient.post<any>(url, formData, requestOptions);
  }









  public ConvertDataArrayBufferToByteArray(arrayBufferData: ArrayBuffer): Uint8Array
  {
    var bytes = new Uint8Array(arrayBufferData);

    return null;
  }

  public Send_AssignPatch(id_patch: string): Observable<any>
  {
    var url: string = CONFIG_SERVER.DOMAIN+CONFIG_SERVER.API_PATCH_ASSIGN;

    var idToken = this.serviceAuth.IdToken;
    let paramsIDToken = new HttpParams().set('id_token', idToken)
                                        .set('id_patch', id_patch);

    var accessToken = this.serviceAuth.AccessToken;
    var authorizationValue = "Bearer " + accessToken;
    var headers: HttpHeaders = new HttpHeaders({
      Authorization: authorizationValue
    });

    const requestOptions: Object = {
      headers,
      params: paramsIDToken,
      responseType: 'text'
    };

    // return this.httpClient.get<string>(url, requestOptions);
    return this.httpClient.put<any>(url, null, requestOptions);
  
  }

  public Send_DeleteMesh(infoMesh: Interface_InfoMesh_Basic): Observable<any>
  {
    var url: string = CONFIG_SERVER.DOMAIN+CONFIG_SERVER.API_MESH_DELETE;

    var idToken = this.serviceAuth.IdToken;
    let paramsIDToken = new HttpParams().set('id_token', idToken)
                                        .set('id_patient', infoMesh.id_patient);

    var accessToken = this.serviceAuth.AccessToken;
    var authorizationValue = "Bearer " + accessToken;
    var headers: HttpHeaders = new HttpHeaders({
      Authorization: authorizationValue
    });

    const requestOptions: Object = {
      headers,
      params: paramsIDToken,
      responseType: 'text'
    };

    // return this.httpClient.get<string>(url, requestOptions);
    return this.httpClient.delete<any>(url, requestOptions);

  }

  public CalculateAllPatchesProgress()
  {
    if(this.map_list_patch != null)
    {
      for(let key of Array.from( this.map_list_patch.keys()) ) 
      {
        this.CalculatePatchProgress(key);
      }
    }


  }

  public CalculatePatchProgress(id_patch: string): boolean
  {
    if(this.map_list_patch.has(id_patch))
    {
      var counter: number = 0;

      var infoPatch: Interface_InfoPatch_Basic = this.map_list_patch.get(id_patch);
      for(var infoMesh of infoPatch.list_mesh_info)
      {
        if(infoMesh.is_saved == true && 
           infoMesh.severity != DEFINES.QUESTION_SEVERITY_DEFAULT &&
           infoMesh.operation != DEFINES.QUESTION_OPERATION_DEFAULT)
        {
          counter++;
        }
      }

      var progress: number = counter;
      infoPatch.progress = progress;

      infoPatch.is_able_to_submitted = (progress == infoPatch.list_mesh_info.length);


      return true;
    }

    return false;

  }

  public SubmitPatch(id_patch: string)
  {
    var isPatchValid: boolean = this.CalculatePatchProgress(id_patch);

    if(isPatchValid)
    {
      var infoPatch: Interface_InfoPatch_Basic = this.map_list_patch.get(id_patch);

      if(infoPatch.is_submitted == false && infoPatch.is_able_to_submitted == true)
      {
        this.isSubmittingPatch = true;
        this.isSubmittingPatchSuccess = false;

        this.Send_SubmitPatch(id_patch).subscribe(
        // this.serviceUser.Send_UpdateProfile().subscribe(
          respMessageSuccess=>{
            console.log(respMessageSuccess);
            infoPatch.is_submitted = true;

            this.isSubmittingPatch = false;
            // this.isSubmittingPatchSuccess = true;
            this.isSubmittingPatchSuccess = false;
            // setTimeout(()=>{
            //   this.isSubmittingPatch = false;
            //   this.isSubmittingPatchSuccess = false;
            // }, DEFINES.TIME_SHOWING_SUCCESS_ALERT);
            // alert("Submit Patch: Successed\n"+respMessageSuccess);
          },
          respMessageError=>{
            console.log(respMessageError);
            this.isSubmittingPatch = false;
            this.isSubmittingPatchSuccess = false;
            alert("Submit Patch: Failed\n"+respMessageError.error);
    
          }
        );
      }
    }
    else
    {
      alert("This patch is not valid to submit!");
    }

    if(this.map_list_patch.has(id_patch))
    {
      var counter: number = 0;

      
    }
  }

  public Send_SubmitPatch(id_patch: string): Observable<any>
  {
    var url: string = CONFIG_SERVER.DOMAIN+CONFIG_SERVER.API_RATER_SUBMIT_PATCH;

    var idToken = this.serviceAuth.IdToken;
    let paramsIDToken = new HttpParams().set('id_token', idToken)
                                        .set('id_patch', id_patch);

    // var body: string = JSON.stringify(this.info);
    var body: string = null;
    var accessToken = this.serviceAuth.AccessToken;
    var authorizationValue = "Bearer " + accessToken;
    var headers: HttpHeaders = new HttpHeaders({
      Authorization: authorizationValue,
      'Content-Type': 'application/json'
    });

    const requestOptions: Object = {
      headers,
      params: paramsIDToken,
      responseType: 'text'
    };

    // return this.httpClient.get<string>(url, requestOptions);
    return this.httpClient.put<any>(url, body, requestOptions);

  }


  public DownloadPatchData(id_patch: string)
  {
    var respMessage = "";

    this.Send_DownloadPatchRatingData(id_patch).subscribe(
    // this.serviceUser.Send_UpdateProfile().subscribe(
      respMessageSuccess=>{
        console.log(respMessageSuccess.length);
        var data = respMessageSuccess;
        

        respMessage += "Download Patch Rating Data: Successed\nClick Ok To SaveAs File\n";

        alert(respMessage);

        this.SaveRatingDataToFile(id_patch, data);

        
      },
      respMessageError=>{
        console.log(respMessageError);

        respMessage += "Download Patch Rating Data: Failed\n"+respMessageError.error;

        return respMessage;

        alert(respMessage);

      }
    );
  }

  public Send_DownloadPatchRatingData(id_patch: string): Observable<any>
  {
    var url: string = CONFIG_SERVER.DOMAIN+CONFIG_SERVER.API_PATCH_DATA;

    var idToken = this.serviceAuth.IdToken;
    let paramsIDToken = new HttpParams().set('id_token', idToken)
                                        .set('id_patch', id_patch);

    // var body: string = JSON.stringify(this.info);
    // var body: string = null;
    var accessToken = this.serviceAuth.AccessToken;
    var authorizationValue = "Bearer " + accessToken;
    var headers: HttpHeaders = new HttpHeaders({
      Authorization: authorizationValue
    });

    const requestOptions: Object = {
      headers,
      params: paramsIDToken,
      responseType: 'text/plain'
    };

    // return this.httpClient.get<string>(url, requestOptions);
    return this.httpClient.get<any>(url, requestOptions);
  }

  public SaveRatingDataToFile(id_patch: string, data: string)
  {
    if(data == null || data.length == 0) 
    {
      console.error('Console.save: No data')
      return;
    }

    var filename = id_patch+".csv";

    var blobFile = new Blob([data], {type: 'text/plain'});
    importedSaveAs(blobFile, filename);
    // var urlFile= window.URL.createObjectURL(blobFile);
    // window.open(urlFile);

  }
}
