import { Component, Input, OnInit, AfterViewInit, Output, EventEmitter, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Interface_InfoMesh_Basic } from 'src/app/Models/InfoMesh';
import * as DEFINES from '../../Configurations/Defines';
import { Auth0Service } from 'src/app/Services/Auth0/auth0.service';
import { UserService } from 'src/app/Services/User/user.service';
import { MeshService } from 'src/app/Services/Mesh/mesh.service';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-mesh-modal',
  templateUrl: './mesh-modal.component.html',
  styleUrls: ['./mesh-modal.component.css']
})
export class MeshModalComponent implements OnInit, AfterViewInit, AfterViewChecked
{

  // @Input() public idList: string;
  @Input() public infoMesh: Partial<Interface_InfoMesh_Basic>;
  @Input() showPatientID: boolean;

  constructor(private changeDetector : ChangeDetectorRef, public serviceAuth: Auth0Service, public serviceUser: UserService, public serviceMESH: MeshService, private modalService: NgbModal, public activeModal: NgbActiveModal) 
  {
    var asdf = this.infoMesh;
  }

  ngOnInit(): void
  {
    var asdf = this.infoMesh;
  }

  ngAfterViewInit(): void
  {
    // throw new Error("Method not implemented.");
    var asdf = this.infoMesh;
  }


  ngAfterViewChecked()
  {
    this.changeDetector.detectChanges();
  }

  // open() {
  //   this.modalService.open(NgbdModal2Content, {
  //     size: 'lg'
  //   });
  // }

  SaveCurrentMeshRating(): boolean
  {
    if(this.serviceUser.info.rater_survey_info == undefined ||
      this.serviceUser.info.rater_survey_info == null ||
      this.serviceUser.info.rater_survey_info.is_survey_submitted == undefined ||
      this.serviceUser.info.rater_survey_info.is_survey_submitted == null ||
      this.serviceUser.info.rater_survey_info.is_survey_submitted == false)
    {
      alert("You need to finish Rater Survey before rating any patient!");
      return false;
    }
    
    if(this.infoMesh.severity == DEFINES.QUESTION_SEVERITY_DEFAULT ||
        this.infoMesh.operation == DEFINES.QUESTION_OPERATION_DEFAULT)
    {
      alert("You need to finish both questions before saving you rating!");
      return false;
    }
    
    var result: boolean = this.serviceMESH.SaveCurrentMesh(this.infoMesh);

    return result;
  }

  OnSave()
  {
    if(this.serviceUser.info.rater_survey_info == undefined ||
      this.serviceUser.info.rater_survey_info == null ||
      this.serviceUser.info.rater_survey_info.is_survey_submitted == undefined ||
      this.serviceUser.info.rater_survey_info.is_survey_submitted == null ||
      this.serviceUser.info.rater_survey_info.is_survey_submitted == false)
    {
      alert("You need to finish Rater Survey before rating any patient!");
      this.activeModal.close('Close click');
      // return false;
    }
    
    if(this.infoMesh.severity == DEFINES.QUESTION_SEVERITY_DEFAULT ||
        this.infoMesh.operation == DEFINES.QUESTION_OPERATION_DEFAULT)
    {
      alert("You need to finish both questions before saving you rating!");
      // return false;
    }
    else
    {
      var result: boolean = this.serviceMESH.SaveCurrentMesh(this.infoMesh);

      this.activeModal.close('Close click');
    }
    


    // return result;
    
    // var result: boolean = this.SaveCurrentMeshRating();

    // // if (result)
    // // {
    // //   alert("Successed: The Rating has been saved!")
    // // }
    // // else
    // // {
    // //   alert("Failed: The Rating has not been saved!")
    // // }

    // this.activeModal.close('Close click');

  }

  OnClose()
  {
    this.serviceMESH.SetMeshDownloadingStatus(false);
    this.activeModal.close('Close click');
  }

  OnPrevious()
  {
    var indexCurrent: number = this.serviceMESH.GetMeshIndex(this.infoMesh);
    var indexPrev: number = indexCurrent-1;
    var result = this.serviceMESH.SwitchCurrentMesh(this.infoMesh.id_patch, indexPrev);
    if(result)
    {
      this.infoMesh=this.serviceMESH.info_current;
    }
  }

  OnNext()
  {
    var indexCurrent: number = this.serviceMESH.GetMeshIndex(this.infoMesh);
    var indexNext: number = indexCurrent+1;
    var result = this.serviceMESH.SwitchCurrentMesh(this.infoMesh.id_patch, indexNext);

    if(result)
    {
      this.infoMesh=this.serviceMESH.info_current;
    }
    
  }

  OnSavePrevious()
  {
    var result: boolean = this.SaveCurrentMeshRating();
    if(result)
    {
      this.OnPrevious();
    }
  }

  OnSaveNext()
  {
    var result: boolean = this.SaveCurrentMeshRating();
    if(result)
    {
      this.OnNext();
    }
  }

  IsValidToDelete()
  {
    var isAdmin = this.serviceUser.IsAdmin();

    if(!isAdmin)
    {
      return false;
    }

    if(this.infoMesh.id_patch != DEFINES.ADMIN_PATCH_ID)
    {
      return false;
    }

    return true;
  }

  OnDelete()
  {
    this.serviceMESH.Send_DeleteMesh(this.infoMesh).subscribe(
      respMessageSuccess=>{
        console.log(respMessageSuccess);

        alert("Delete Mesh: Successed!\n"+respMessageSuccess);
      },
      respMessageError=>{
        console.log(respMessageError);

        alert("Delete Mesh: Failed!\n"+respMessageError);

        
      }
    );
  }
}
