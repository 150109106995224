import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { saveAs as importedSaveAs } from "file-saver";

import { CONFIG_SERVER } from '../../Configurations/Config_Server';
import * as DEFINES from '../../Configurations/Defines';
import { Auth0Service } from '../Auth0/auth0.service';
import { Interface_InfoUser_Basic } from '../../Models/InfoUser'
import { Interface_InfoMesh_Basic } from 'src/app/Models/InfoMesh';
import { userInfo } from 'os';

// March 22, 2021 - note that almost everything in this file has been superceded by Auth0

@Injectable({
  providedIn: 'root'
})
export class UserService 
{

  public isLoadingUserProfile: boolean = false;
  public isLoadingUserProfileSuccess = false;
  public isCreatingUserProfile: boolean = false;
  public isForbidden = true;
  

  public info: Partial<Interface_InfoUser_Basic> = 
  {
    
  };

  constructor(public router: Router, public serviceAuth: Auth0Service, private httpClient: HttpClient) 
  { 

  }

  Send_TestUserCounter(): Observable<any>
  {
    var url: string = CONFIG_SERVER.DOMAIN+CONFIG_SERVER.API_USER_COUNTER;
    var accessToken = this.serviceAuth.AccessToken;
    var authorizationValue = "Bearer " + accessToken;
    var headers: HttpHeaders = new HttpHeaders({
      Authorization: authorizationValue
    });

    const requestOptions: Object = {
      headers,
      responseType: 'text'
    };

    return this.httpClient.get<string>(url, requestOptions);
  }

  Send_CreateUser(
    access_code: string,
    pe_currently_practice_institution: string = DEFINES.CURRENT_WORKING_INSTITUTION_UNDEFINED,
    pe_firstname: string = DEFINES.CURRENT_FIRSTNAME_UNDEFINED,
    pe_lastname: string = DEFINES.CURRENT_LASTNAME_UNDEFINED,
    ): Observable<any>
  {
    access_code = DEFINES.ACCESS_CODE_DEFAULT;
    
    var url: string = CONFIG_SERVER.DOMAIN+CONFIG_SERVER.API_USERS+CONFIG_SERVER.API_USER_CREATE;

    var id_auth0 = this.serviceAuth.IdAuth0;
    let paramsIDToken = new HttpParams().set('id_auth0', id_auth0)
                                        .set('access_code', access_code)
                                        .set('first_name', pe_firstname)
                                        .set('last_name', pe_lastname)
                                        .set('institute', pe_currently_practice_institution);

    var accessToken = this.serviceAuth.AccessToken;
    var authorizationValue = "Bearer " + accessToken;
    var headers: HttpHeaders = new HttpHeaders({
      Authorization: authorizationValue,
      'Content-Type': 'application/json'
    });
    
    const requestOptions: Object = {
      headers,
      params: paramsIDToken,
      responseType: 'application/json'     // purposely left broken so it doesn't get passed though
    };

    // return this.httpClient.get<string>(url, requestOptions);
    return this.httpClient.post<any>(url, { }, requestOptions);
  }

  Send_LoadProfile(): Observable<any>
  {
    var url: string = CONFIG_SERVER.DOMAIN+CONFIG_SERVER.API_USERS;
  
    var id_auth0 = this.serviceAuth.IdAuth0;
    let paramsIDToken = new HttpParams().set('id_auth0', id_auth0);
    
    var accessToken = this.serviceAuth.AccessToken;
    var authorizationValue = "Bearer " + accessToken;
    var headers: HttpHeaders = new HttpHeaders({
      Authorization: authorizationValue,
      'Content-Type': 'application/json'
    });

    const requestOptions: Object = {
      headers,
      params: paramsIDToken,
      responseType: 'application/json'     // purposely left broken so it doesn't get passed though
    };

    return this.httpClient.get<any>(url, requestOptions);
  }

  // TODO: unused?
  Send_UpdateProfile(): Observable<any>
  {
    var url: string = CONFIG_SERVER.DOMAIN+CONFIG_SERVER.API_USER_UPDATE_PROFILE;

    const id_auth0 = this.serviceAuth.IdAuth0;
    let paramsIDToken = new HttpParams().set('id_auth0', id_auth0);

    var body = this.ConvertUserInfoToJsonString();
    var accessToken = this.serviceAuth.AccessToken;
    var authorizationValue = "Bearer " + accessToken;
    var headers: HttpHeaders = new HttpHeaders({
      Authorization: authorizationValue,
      'Content-Type': 'application/json'
    });

    const requestOptions: Object = {
      headers,
      params: paramsIDToken,
      responseType: 'text'
    };

    // return this.httpClient.get<string>(url, requestOptions);
    return this.httpClient.put<any>(url, body, requestOptions);

  }

  Send_UpdatedProfileInstitute(firstname :string, lastname :string, institute :string): Observable<any>
  {
    var url: string = CONFIG_SERVER.DOMAIN+CONFIG_SERVER.API_USER_UPDATE_PROFILE_INSTITUTE;

    var idToken = this.serviceAuth.IdToken;
    let paramsIDToken = new HttpParams()
      .set('id_token', idToken)
      .set('firstname', firstname)
      .set('lastname', lastname)
      .set('institute', institute);

    var body = '';

    var accessToken = this.serviceAuth.AccessToken;

    var authorizationValue = "Bearer " + accessToken;
    var headers: HttpHeaders = new HttpHeaders({
      Authorization: authorizationValue,
      'Content-Type': 'application/json'
    });

    const requestOptions: Object = {
      headers,
      params: paramsIDToken,
      responseType: 'text'
    };

    return this.httpClient.put<any>(url, body, requestOptions);
  }

  Send_SubmitRaterSurvey(): Observable<any>
  {
    var url: string = CONFIG_SERVER.DOMAIN+CONFIG_SERVER.API_RATER_SUBMIT_SURVEY;

    var idToken = this.serviceAuth.IdToken;
    let paramsIDToken = new HttpParams().set('id_token', idToken);

    var body: string = JSON.stringify(this.info.rater_survey_info, (key, value) => {
      if (value !== null) return value
    });

    var accessToken = this.serviceAuth.AccessToken;
    var authorizationValue = "Bearer " + accessToken;
    var headers: HttpHeaders = new HttpHeaders({
      Authorization: authorizationValue,
      'Content-Type': 'application/json'
    });

    const requestOptions: Object = {
      headers,
      params: paramsIDToken,
      responseType: 'text'
    };

    // return this.httpClient.get<string>(url, requestOptions);
    return this.httpClient.put<any>(url, body, requestOptions);

  }

  public UpdateLocalMeshInfo(infoMeshNew: Interface_InfoMesh_Basic): boolean
  {
    var isUpdated: boolean = false;
    for(let infoPatch of this.info.list_patch)
    {
      if(infoPatch.id_patch == infoMeshNew.id_patch)
      {
        for(let infoMesh of infoPatch.list_mesh_info)
        {
          if(infoMesh.id == infoMeshNew.id &&
             infoMesh.id_patch == infoMeshNew.id_patch &&
             infoMesh.id_patient == infoMeshNew.id_patient &&
             infoMesh.id_key_s3 == infoMeshNew.id_key_s3)
          {
            infoMesh.severity = infoMeshNew.severity;
            infoMesh.operation = infoMeshNew.operation;
            isUpdated = true;
          }

          if(isUpdated)
          {
            break;
          }
        }
      }

      if(isUpdated)
      {
        break;
      }
    }

    return isUpdated;
  }

  private HandleHttpError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private ConvertUserInfoToJsonString(): string
  {
    // JSON()
    var body: string = JSON.stringify(this.info, (key, value) => {
      if (value !== null) return value
    });

    return body;
  }

  public TestGetResp(response)
  {
    var asdf = 0;
  }

  public IsAdmin()
  {
    if(this.info.type_account != DEFINES.TYPE_USER_ADMIN)
    {
      return false;
    }

    return true;
  }

  public SetLoadingStatus(isLoading: boolean, isLoadingSuccess: boolean)
  {
    this.isLoadingUserProfile = isLoading;
    this.isLoadingUserProfileSuccess = isLoadingSuccess;
  }



  public DownloadRaterSurveyData()
  {
    var respMessage = "";

    this.Send_DownloadRaterSurveyData().subscribe(
    // this.serviceUser.Send_UpdateProfile().subscribe(
      respMessageSuccess=>{
        console.log(respMessageSuccess.length);
        var data = respMessageSuccess;
        

        respMessage += "Download Rater Survey Data: Successed\nClick Ok To SaveAs File\n";

        alert(respMessage);

        this.SaveRaterSurveyDataToFile(data);

        
      },
      respMessageError=>{
        console.log(respMessageError);

        respMessage += "Download Rater Survey Data: Failed\n"+respMessageError.error;

        return respMessage;

        alert(respMessage);

      }
    );
  }

  public Send_DownloadRaterSurveyData(): Observable<any>
  {
    var url: string = CONFIG_SERVER.DOMAIN+CONFIG_SERVER.API_RATER_DOWNLOAD_SURVEY;

    var idToken = this.serviceAuth.IdToken;
    let paramsIDToken = new HttpParams().set('id_token', idToken);

    // var body: string = JSON.stringify(this.info);
    // var body: string = null;
    var accessToken = this.serviceAuth.AccessToken;
    var authorizationValue = "Bearer " + accessToken;
    var headers: HttpHeaders = new HttpHeaders({
      Authorization: authorizationValue
    });

    const requestOptions: Object = {
      headers,
      params: paramsIDToken,
      responseType: 'text/plain'
    };

    // return this.httpClient.get<string>(url, requestOptions);
    return this.httpClient.get<any>(url, requestOptions);
  }

  public SaveRaterSurveyDataToFile(data: string)
  {
    if(data == null || data.length == 0) 
    {
      console.error('Console.save: No data')
      return;
    }

    var filename = "RaterSurveyData.csv";

    var blobFile = new Blob([data], {type: 'text/plain'});
    importedSaveAs(blobFile, filename);
    // var urlFile= window.URL.createObjectURL(blobFile);
    // window.open(urlFile);

  }
}
