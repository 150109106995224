import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Interface_InfoUser_Basic, Interface_InfoUser_Job } from 'src/app/Models/InfoUser';
import { Auth0Service } from 'src/app/Services/Auth0/auth0.service';
import { UserService } from 'src/app/Services/User/user.service';


@Component({
  selector: 'app-user-profile-modal',
  templateUrl: './user-profile-modal.component.html',
  styleUrls: ['./user-profile-modal.component.css']
})
export class UserProfileModalComponent implements OnInit 
{
  public infoUser: Interface_InfoUser_Basic;

  public area_add: string = "";

  public job_add: Interface_InfoUser_Job = 
  {
    job_title: "",
    name_institution: "",
    num_of_years: 0
  };

  constructor(public serviceAuth: Auth0Service, public serviceUser: UserService, private modalService: NgbModal, public activeModal: NgbActiveModal) 
  { 
    
  }

  ngOnInit() 
  {
    
  }

  OnSave()
  {

  }

  OnRemoveArea(area_practice: string)
  {
    const index: number = this.infoUser.professional_info.list_areas_of_practice.indexOf(area_practice);
    if (index !== -1) {
      this.infoUser.professional_info.list_areas_of_practice.splice(index, 1);
    }
  }

  OnAddArea()
  {
    if(this.area_add.length <= 0)
    {
      alert("Invalid Area of Practice!")
      return;
    }

    const index: number = this.infoUser.professional_info.list_areas_of_practice.indexOf(this.area_add);
    if (index == -1) 
    {
      this.infoUser.professional_info.list_areas_of_practice.push(this.area_add);
    }
  }

  OnRemoveJob(infoJob: Interface_InfoUser_Job)
  {
    var index: number = -1;

    for(var idx:number = 0; idx<this.infoUser.professional_info.list_job_info.length; ++idx)
    {
      var infoCurrent = this.infoUser.professional_info.list_job_info[idx];

      if(infoJob.job_title == infoCurrent.job_title &&
         infoJob.name_institution == infoCurrent.name_institution &&
         infoJob.num_of_years == infoCurrent.num_of_years)
      {
        index = idx;
        break;
      }
    }

    if(index!=-1)
    {
      this.infoUser.professional_info.list_job_info.splice(index, 1);
    }
  }

  OnAddJob()
  {
    var index: number = -1;

    if(this.job_add.job_title.length <= 0 || this.job_add.name_institution.length <= 0)
    {
      alert("Invalid Job Title or Institution Name!")
      return;
    }

    
    var adfsa = isNaN(this.job_add.num_of_years);
    if(isNaN(this.job_add.num_of_years))
    {
      alert("Only Accept Numbers for Years!")
      return;
    }

    for(var idx:number = 0; idx<this.infoUser.professional_info.list_job_info.length; ++idx)
    {
      var infoCurrent = this.infoUser.professional_info.list_job_info[idx];

      if(this.job_add.job_title == infoCurrent.job_title &&
         this.job_add.name_institution == infoCurrent.name_institution &&
         this.job_add.num_of_years == infoCurrent.num_of_years)
      {
        index = idx;
        break;
      }
    }

    if(index == -1)
    {
      var numYears: number = Number(this.job_add.num_of_years);
      var job_new: Interface_InfoUser_Job =
      {
        job_title: this.job_add.job_title,
        name_institution: this.job_add.name_institution,
        num_of_years: numYears,
      }
      this.infoUser.professional_info.list_job_info.push(job_new);
    }
  }

}
