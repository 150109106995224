import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { Interface_InfoMesh_Basic } from 'src/app/Models/InfoMesh';
import { UserService } from 'src/app/Services/User/user.service';
import { Auth0Service } from 'src/app/Services/Auth0/auth0.service';
import { MeshService } from 'src/app/Services/Mesh/mesh.service';


@Component({
  selector: 'app-mesh-main',
  templateUrl: './mesh-main.component.html',
  styleUrls: ['./mesh-main.component.css']
})
export class MeshMainComponent implements OnInit, AfterViewInit 
{

  public showPatientID: boolean = false;
  
  constructor(public serviceUser: UserService, public serviceAUTH: Auth0Service, public serviceMESH: MeshService)
  { 

  }

  ngOnInit() 
  {

  }

  ngAfterViewInit()
  {
    
  }

}
