import { Component, OnInit } from '@angular/core';
import { Auth0Service } from '../../Services/Auth0/auth0.service';
import { CRANIORATE_FRONTEND_VERSION } from '../../Configurations/Defines';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.css']
})
export class CallbackComponent implements OnInit {

  version: string = CRANIORATE_FRONTEND_VERSION;

  constructor(public auth: Auth0Service)  
  { 
    auth.HandleAuthentication();
  }

  ngOnInit() 
  {
  }

}
