import { Component, OnInit, Input, AfterViewInit, ViewChild, ElementRef, HostListener, OnChanges } from '@angular/core';
import * as THREE from 'three';
import * as THREE_FULL from 'three-full';
import * as DEFINES from 'src/app/Configurations/Defines';
import { Interface_InfoMesh_Basic } from 'src/app/Models/InfoMesh';
import { Auth0Service } from 'src/app/Services/Auth0/auth0.service';
import { MeshService } from 'src/app/Services/Mesh/mesh.service';

@Component({
  selector: 'app-mesh-visualize',
  templateUrl: './mesh-visualize.component.html',
  styleUrls: ['./mesh-visualize.component.css']
})
export class MeshVisualizeComponent implements OnInit, AfterViewInit, OnChanges  
{

  public FIELD_OF_VIEW: number = 60;
  public NEAR_CLIPPING_PLANE: number= 1;
  public FAR_CLIPPING_PLANE = 1100;
  public SIZE_AXE = 1100;
  public COLOR_BACKGROUND: THREE.Color = new THREE.Color( 0x000000 );

  @Input() public infoMesh: Partial<Interface_InfoMesh_Basic>;
  @Input() public isPreview: boolean = false;

  // private isDownloading = false;



  @ViewChild('refCanvas')
  private refCanvas: ElementRef;

  private renderer: THREE.WebGLRenderer;
  private camera: THREE.PerspectiveCamera;
  private cameraTarget: THREE.Vector3;
  private cameraOrth: THREE.OrthographicCamera;
  private scene: THREE.Scene;
  private meshVTK: THREE.Mesh;
  private scalarZoom: number = 2.2;



  private isMouseDragging: boolean = false;
  private posX_Pre: number = 0;
  private posY_Pre: number = 0;
  private speedRotation: number = 0.02;

  private aspectRatio: number = 4/3;

  

  // public controls: THREE_FULL.OrbitControls;


  
  constructor(public serviceAUTH: Auth0Service, public serviceMESH: MeshService) 
  { 
    // this.RenderScene = this.RenderScene.bind(this);
    // this.OnColladaModelLoadingCompleted = this.OnColladaModelLoadingCompleted.bind(this);
    // this.OnVTKModelLoadingCompleted = this.OnVTKModelLoadingCompleted.bind(this);
    
  }

  ngOnInit() 
  {
    this.RenderScene = this.RenderScene.bind(this);
    this.OnColladaModelLoadingCompleted = this.OnColladaModelLoadingCompleted.bind(this);
    this.OnVTKModelLoadingCompleted = this.OnVTKModelLoadingCompleted.bind(this);
    this.OnMeshDataDownloadFinished = this.OnMeshDataDownloadFinished.bind(this);


  }

  ngAfterViewInit()
  {
    this.CreateScene();
    // this.CreateLight();
    this.CreateCamera();
    this.StartRendering();
    this.InitModel();
    this.OnResize(null);
  }

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void
  {
    this.ReestView();
  }

  private ReestView(): void
  {
    if(this.scene != null)
    {
      this.cameraOrth.position.set(0, 0, 0);
      this.InitModel();
      this.OnResize(null);
    }

  }

  public get GetCanvas(): HTMLCanvasElement 
  {
    return this.refCanvas.nativeElement;
  }

  private CreateScene() 
  {
    this.scene = new THREE.Scene();
    this.scene.background = this.COLOR_BACKGROUND;
    this.scene.add(new THREE.AxesHelper(this.SIZE_AXE));

    // var loaderCollada = new THREE_FULL.ColladaLoader();
    // loaderCollada.load('assets/MeshesTemp/multimaterial.dae', this.OnColladaModelLoadingCompleted);
    
  }

  private InitModel()
  {
    // this.m_modelSelected = LIST_MODELS_VTK[1];
    if(this.infoMesh != null)
    {
      this.ReloadModel();
    }
    
  }

  private OnColladaModelLoadingCompleted(collada) 
  {
    var modelScene = collada.scene;
    this.scene.add(modelScene);
    this.RenderScene();
  }

  private OnVTKModelLoadingCompleted(geometryVTK) 
  {
    geometryVTK.center();
    geometryVTK.computeVertexNormals();
    var materialVTK = new THREE.MeshLambertMaterial( { color: 0xffffff, side: THREE.DoubleSide } );

    var meshVTK = new THREE.Mesh( geometryVTK, materialVTK );
    meshVTK.position.set( 0, 0, -500 );
    // meshVTK.scale.multiplyScalar(2);
    meshVTK.scale.setScalar(this.scalarZoom);
    meshVTK.rotation.z = DEFINES.MESH_DEFAULT_ANGEL_Z;
    meshVTK.rotation.x = DEFINES.MESH_DEFAULT_ANGEL_X;

    this.meshVTK = meshVTK;
    this.scene.add(meshVTK);
          
    this.RenderScene();
  }

  public ReloadModel()
  {
    this.scene.remove(this.meshVTK);
    var loaderVTK = new THREE_FULL.VTKLoader();

    // To Do: Wait for the backend data streaming finished
    // var data_mesh: string = this.serviceMESH.DownloadMeshData(DEBUG_LIST_ID, this.infoMesh.id);
    // this.infoMesh.data_mesh;

    // loaderVTK.load(this.infoMesh.data_mesh, this.OnVTKModelLoadingCompleted);
    // var dataDownloaded = this.serviceMESH.DownloadMeshData(this.infoMesh, this.OnMeshDataDownloadFinished);
    // var geometryVTK = loaderVTK.parse(this.infoMesh.data_mesh);

    // this.OnVTKModelLoadingCompleted(geometryVTK);

    if(this.isPreview == false)
    {
      var data: object = this.serviceMESH.LoadMeshDataFromLocal(this.infoMesh.id_key_s3);

      if (data != null)
      {
        var loaderVTK = new THREE_FULL.VTKLoader();
        var geometryVTK = loaderVTK.parse(data);
        this.OnVTKModelLoadingCompleted(geometryVTK);
      }
      else
      {
        if(this.serviceMESH.isDownloadingMeshData == false)
        {
          // this.isDownloading = true;
          this.serviceMESH.SetMeshDownloadingStatus(true);

          this.serviceMESH.Send_DownloadMeshData(this.infoMesh, this.OnMeshDataDownloadFinished).subscribe(
            respSuccess=>{
              console.log(respSuccess);
      
              // alert("Skull Download: Successed");
              var fileReader: FileReader = new FileReader();
              let file = new Blob([respSuccess], { type: 'arraybuffer' });
              
              fileReader.onloadend = this.OnMeshDataDownloadFinished;
      
              fileReader.readAsArrayBuffer(file);

              // this.isDownloading = false;
              this.serviceMESH.SetMeshDownloadingStatus(false);
      
      
              // this.infoMesh.data_mesh = respSuccess;
              // this.OnMeshDataDownloadFinished(this.infoMesh.data_mesh);
            },
            respMessageError=>{
              console.log(respMessageError);

              // this.isDownloading = false;
              this.serviceMESH.SetMeshDownloadingStatus(false);
      
              alert("Skull Download: Failed");
            }
          );
        }
        else
        {
          alert("You are already downloading skull data.\nPlease wait for the downloading finished first!");
        }
        
      }
    }
    else
    {
      var data: object = this.infoMesh.data_mesh;
      
      var loaderVTK = new THREE_FULL.VTKLoader();
      var geometryVTK = loaderVTK.parse(data);
      this.OnVTKModelLoadingCompleted(geometryVTK);
    }
    
    
  }

  private OnMeshDataDownloadFinished(event)
  {
    // console.log("AAAAAA="+typeof(event.target.result));
    var data = event.target.result;
    this.serviceMESH.SaveMeshDataToLocal(this.infoMesh.id_key_s3, data);

    var loaderVTK = new THREE_FULL.VTKLoader();
    var geometryVTK = loaderVTK.parse(data);
    this.OnVTKModelLoadingCompleted(geometryVTK);
  }

  private CreateCamera() 
  {
    
    var frustumSize = 500;
    var aspect = this.GetAspectRatio();

    this.cameraOrth = new THREE.OrthographicCamera(
      frustumSize * this.aspectRatio / -2, 
      frustumSize * this.aspectRatio / 2, 
      frustumSize / 2,
      frustumSize / -2,
      1,
      1000);

    this.cameraOrth.position.set(0, 0, 0);

    var dirLightOrth = new THREE.DirectionalLight( 0x606060 );
    dirLightOrth.position.set( 200, 200, 1000 );

    this.cameraOrth.add( dirLightOrth );
    this.cameraOrth.add( dirLightOrth.target );

    // var ambientLight = new THREE.AmbientLight( 0x404040 ); // soft white light
    var ambientLight = new THREE.AmbientLight( 0x707070 ); // soft white light
    this.scene.add( ambientLight );

    this.scene.add(this.cameraOrth);

  }

  private GetAspectRatio(): number 
  {
    let height = this.refCanvas.nativeElement.clientHeight;
    if (height === 0) {
        return 0;
    }
    return this.refCanvas.nativeElement.clientWidth / this.refCanvas.nativeElement.clientHeight;
  }

  private StartRendering() {
    this.renderer = new THREE.WebGLRenderer({
        canvas: this.refCanvas.nativeElement,
        antialias: true
    });
    this.renderer.setPixelRatio(devicePixelRatio);
    this.renderer.setSize(this.refCanvas.nativeElement.clientWidth, this.refCanvas.nativeElement.clientHeight);

    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    this.renderer.setClearColor(0xffffff, 1);
    this.renderer.autoClear = true;

    let component: MeshVisualizeComponent = this;

    (function render() 
    {
        component.RenderScene();
    }());
  }

  public RenderScene() 
  {
    
    this.renderer.render(this.scene, this.cameraOrth);
  }

  public OnTopButton()
  {
      this.meshVTK.rotation.x = 0;
      this.meshVTK.rotation.y = 0;
      this.meshVTK.rotation.z = 0;
      this.RenderScene();
  }

  public OnFrontButton()
  {
      this.meshVTK.rotation.x = 0;
      this.meshVTK.rotation.y = 0;
      this.meshVTK.rotation.z = 0;

      this.meshVTK.rotation.x = Math.PI*1.5;

      this.RenderScene();
  }

  public OnBackButton()
  {
      this.meshVTK.rotation.x = 0;
      this.meshVTK.rotation.y = 0;
      this.meshVTK.rotation.z = 0;

      this.meshVTK.rotation.x = Math.PI / 2;
      this.meshVTK.rotation.y = Math.PI;

      this.RenderScene();
  }

  public OnLeftButton()
  {
    this.meshVTK.rotation.x = 0;
    this.meshVTK.rotation.y = 0;
    this.meshVTK.rotation.z = 0;
          
    this.meshVTK.rotation.x = Math.PI / 2;
    this.meshVTK.rotation.y = Math.PI;
    this.meshVTK.rotation.z = Math.PI / 2;

    this.RenderScene();
  }

  public OnRightButton()
  {
    this.meshVTK.rotation.x = 0;
    this.meshVTK.rotation.y = 0;
    this.meshVTK.rotation.z = 0;

    this.meshVTK.rotation.x = Math.PI / 2;
    this.meshVTK.rotation.y = Math.PI;
    this.meshVTK.rotation.z = Math.PI * 1.5;

    this.RenderScene();
  }

  public OnBottomButton()
  {
      this.meshVTK.rotation.x = 0;
      this.meshVTK.rotation.y = 0;
      this.meshVTK.rotation.z = 0;
      
      this.meshVTK.rotation.x = Math.PI;
      // this.meshVTK.rotation.y = Math.PI;

      this.RenderScene();
  }

  public OnZoomChange(valueZoomScale: number)
  {
      // this.meshVTK.scale.setScalar(valueZoomScale);
      this.scalarZoom = valueZoomScale;
      this.meshVTK.scale.setScalar(this.scalarZoom);
      this.RenderScene();

  }

  @HostListener('mousedown', ['$event'])
  OnMouseDown(event: MouseEvent) 
  {
    var posX = event.clientX;
    var posY = event.clientY;

    var rect = this.refCanvas.nativeElement.getBoundingClientRect();

    if(this.CheckMousePositionInCanvas(posX, posY, rect))
    {
      this.isMouseDragging = true;
    }
  }

  @HostListener('touchstart', ['$event'])
  OnTouchStart(event: any) 
  {
    if(event.targetTouches != undefined && event.targetTouches != null && event.targetTouches.length == 1)
    {
      var posX = event.targetTouches[0].clientX;
      // var posX = event.clientX;
      var posY = event.targetTouches[0].clientY;
    


      var rect = this.refCanvas.nativeElement.getBoundingClientRect();

      if(this.CheckMousePositionInCanvas(posX, posY, rect))
      {
        this.isMouseDragging = true;
        this.posX_Pre = posX;
        this.posY_Pre = posY;
      }
    }
  }

  @HostListener('mouseup', ['$event'])
  OnMouseUp(event: MouseEvent) 
  {
    this.isMouseDragging = false;
  }

  @HostListener('touchend', ['$event'])
  OnTouchEnd(event: any) 
  {
    this.isMouseDragging = false;
  }

  @HostListener('touchcancel', ['$event'])
  OnTouchCancel(event: any) 
  {
    this.isMouseDragging = false;
  }

  @HostListener('mousemove', ['$event'])
  OnMouseMove(event: MouseEvent) 
  {
    var posX = event.clientX;
    var posY = event.clientY;
    if (this.isMouseDragging) 
    {
      // The rotation speed factor
      // dx and dy here are how for in the x or y direction the mouse moved
      // var factor = 10/state.canvas.height;
      // var factor = 10/500;
      var dx = this.speedRotation * (posX - this.posX_Pre);
      var dy = this.speedRotation * (posY - this.posY_Pre);

      
      // update the latest angle
      this.meshVTK.rotation.z = this.meshVTK.rotation.z + dx;
      this.meshVTK.rotation.x = this.meshVTK.rotation.x + dy;
      console.log("=====================");
      console.log("Z: " + this.meshVTK.rotation.z.toString());
      console.log("X: " + this.meshVTK.rotation.x.toString());
      this.RenderScene();

    }
    // update the last mouse position
    this.posX_Pre = posX;
    this.posY_Pre = posY;
  }

  @HostListener('touchmove', ['$event'])
  OnTouchMove(event: any) 
  {


    if (this.isMouseDragging) 
    {
      if(event.targetTouches != undefined && event.targetTouches != null && event.targetTouches.length == 1)
      {
        
        var posX = event.targetTouches[0].clientX;
        var posY = event.targetTouches[0].clientY;

        var dx = this.speedRotation * (posX - this.posX_Pre);
        var dy = this.speedRotation * (posY - this.posY_Pre);

        // The rotation speed factor
        // dx and dy here are how for in the x or y direction the mouse moved
        // var factor = 10/state.canvas.height;
        // var factor = 10/500;

        var dx = this.speedRotation * (posX - this.posX_Pre);
        var dy = this.speedRotation * (posY - this.posY_Pre);
  
        // update the latest angle
        this.meshVTK.rotation.z = this.meshVTK.rotation.z + dx;
        this.meshVTK.rotation.x = this.meshVTK.rotation.x + dy;
        this.RenderScene();

        // update the last mouse position
        this.posX_Pre = posX;
        this.posY_Pre = posY;
      }
    }

  }

  SwipeHandler() 
  {
    alert('Swipe handled!');
  }

  private CheckMousePositionInCanvas(posXMouse: number, posYMouse: number, rectCanvas: DOMRect): boolean
  {
    let xStart = rectCanvas.left;
    let xEnd = rectCanvas.right;
    let yStart = rectCanvas.top;
    let yEnd = rectCanvas.bottom;

    if ((posXMouse > xStart && posXMouse < xEnd) && (posYMouse > yStart && posYMouse < yEnd))
    {
        return true;
    }
    return false;
  }

  @HostListener('window:resize', ['$event'])
  public OnResize(event: Event) 
  {
      this.refCanvas.nativeElement.style.width = "90%";
      this.refCanvas.nativeElement.style.height = "90%";
      var widthCurrent = this.refCanvas.nativeElement.clientWidth;
      var heightCurrent = this.refCanvas.nativeElement.clientHeight;

      // this.renderer.domElement.style.width = "100%";
      // this.renderer.domElement.style.height = "100%";
      // var widthCurrent = this.renderer.domElement.clientWidth;
      // var heightCurrent = this.renderer.domElement.clientHeight;

      // console.log("OnResize: " + widthCurrent + ", " + heightCurrent);


      // this.camera.updateProjectionMatrix();
      // this.cameraOrth.updateProjectionMatrix();

      this.renderer.setSize(widthCurrent, widthCurrent/this.aspectRatio);
      
      this.RenderScene();
  }

}
