import { Component, OnInit, AfterViewInit } from '@angular/core';

import { Router } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Interface_InfoUser_Basic } from 'src/app/Models/InfoUser';
import * as DEFINES from '../../Configurations/Defines';
import { Auth0Service } from 'src/app/Services/Auth0/auth0.service';
import { UserService } from 'src/app/Services/User/user.service';
import { Injectable } from '@angular/core';

@Component({
  selector: 'app-user-agreement-modal',
  templateUrl: './user-agreement-modal.component.html',
  styleUrls: ['./user-agreement-modal.component.css']
})
@Injectable()
export class UserAgreementModalComponent implements OnInit
{

  strAccessCode: string = DEFINES.ACCESS_CODE_DEFAULT;

  public isPreview: boolean = false;
  pe_firstname: string;
  pe_lastname: string;
  pe_currently_practice_institution: string;
  agreeReady: boolean;

  constructor(public router: Router, public serviceAuth: Auth0Service, public serviceUser: UserService, private modalService: NgbModal, public activeModal: NgbActiveModal) 
  { 

  }

  ngOnInit() 
  {
    this.pe_currently_practice_institution = '';
    this.pe_firstname = '';
    this.pe_lastname = '';
    this.agreeReady = false;
  }

  OnChangeAccessCode(value)
  {
    this.strAccessCode = value;
  }

  OnAgree()
  {
    var access_code = this.strAccessCode;

    if(access_code.length<=0)
    {
      alert("Please enter access code first!");
      return;
    }

    this.serviceUser.Send_LoadProfile().subscribe(
      respMessageSuccess=>{
        
        // user institute needs to be updated, though account has been created

        console.log('stop');

        var userInfo: Interface_InfoUser_Basic = JSON.parse(respMessageSuccess);
        this.serviceUser.info = userInfo;
        this.serviceUser.isForbidden = false;
        this.serviceUser.SetLoadingStatus(false, false);

        this.serviceUser.Send_UpdatedProfileInstitute(
          this.pe_firstname,
          this.pe_lastname,
          this.pe_currently_practice_institution
          ).subscribe(
            respMessageSuccess=>{
              console.log(respMessageSuccess);
              alert("Overwrite User Profile: Successed!\nPlease log in again using your credentials to continue.\n"+respMessageSuccess);
              this.serviceAuth.Logout();

            },
            respMessageError=>{
              console.log(respMessageError.error);
    
              alert("Overwrite User Profile: Failed!\n"+respMessageError.error);
            }          
          );

        console.log('sto4');

      },
      respMessageError=>{
        // user account needs to be created
        // they just signed up, so the backend doesn't know they exist yet?
        
        this.serviceUser.Send_CreateUser(
          access_code,
          this.pe_firstname,
          this.pe_lastname,
          this.pe_currently_practice_institution,
          ).subscribe(
            respMessageSuccess=>{
              console.log(respMessageSuccess);
    
              alert("User creation successful.");
            },
            respMessageError=>{
              console.log(respMessageError.error);
    
              if (DEFINES.DEV_MODE) {              
                alert("Create user profile failed:\n"+respMessageError.error+
                      "\nBe sure to set backend IP explicitly (DOMAIN in Config_Server.js)");
              }
              else {
                alert("Create user profile failed. Please try again\n"+respMessageError.error);
              }

              this.serviceAuth.Logout();
            }
        );

      }
    );



    var asdf = 0;
  }

}
