import { Component, OnInit } from '@angular/core';
import { Auth0Service } from 'src/app/Services/Auth0/auth0.service';
import { MeshService } from 'src/app/Services/Mesh/mesh.service';

@Component({
  selector: 'app-patch-download-rating-data',
  templateUrl: './patch-download-rating-data.component.html',
  styleUrls: ['./patch-download-rating-data.component.css']
})
export class PatchDownloadRatingDataComponent implements OnInit {

  id_patch: string = "";
  
  constructor(public serviceAUTH: Auth0Service, public serviceMESH: MeshService)
  { 

  }

  ngOnInit() 
  {
  }

  OnChangePatchID(value)
  {
    this.id_patch = value;
  }

  OnDownloadPatchRatingData()
  {
    //EJAY_TEST_PATCH_DONT_DELETE
    this.serviceMESH.DownloadPatchData(this.id_patch);
  }

}
