import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import * as DEFINES from "../../Configurations/Defines";
import { Interface_InfoMesh_Basic } from 'src/app/Models/InfoMesh';

@Component({
  selector: 'app-mesh-rate',
  templateUrl: './mesh-rate.component.html',
  styleUrls: ['./mesh-rate.component.css']
})
export class MeshRateComponent implements OnInit, OnChanges
{

  @Input() public infoMesh: Partial<Interface_InfoMesh_Basic>;
  
  constructor() 
  { 

  }

  ngOnInit() 
  {

  }

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void
  {
    // console.log(this.infoMesh.index);
  }

  OnSliderBarValueUpdate(): number
  {
    if(this.infoMesh == undefined || this.infoMesh == null || this.infoMesh.severity == undefined)
    {
      return 5;
    }

    return this.infoMesh.severity;
  }

  OnSliderBarValueChange(value: number)
  {
    var infoMeshUpdate: Partial<Interface_InfoMesh_Basic> = {severity: value};
    Object.assign(this.infoMesh, infoMeshUpdate);
  }

  OnRateSeverity_0()
  {
    // this.infoMesh.severity = 0;
    var infoMeshUpdate: Partial<Interface_InfoMesh_Basic> = {severity: 0};
    Object.assign(this.infoMesh, infoMeshUpdate);
  }

  OnRateSeverity_1()
  {
    // this.infoMesh.severity = 1;
    var infoMeshUpdate: Partial<Interface_InfoMesh_Basic> = {severity: 1};
    Object.assign(this.infoMesh, infoMeshUpdate);
  }

  OnRateSeverity_2()
  {
    // this.infoMesh.severity = 2;
    var infoMeshUpdate: Partial<Interface_InfoMesh_Basic> = {severity: 2};
    Object.assign(this.infoMesh, infoMeshUpdate);
  }

  OnRateSeverity_3()
  {
    // this.infoMesh.severity = 3;
    var infoMeshUpdate: Partial<Interface_InfoMesh_Basic> = {severity: 3};
    Object.assign(this.infoMesh, infoMeshUpdate);
  }

  OnRateSeverity_4()
  {
    // this.infoMesh.severity = 4;
    var infoMeshUpdate: Partial<Interface_InfoMesh_Basic> = {severity: 4};
    Object.assign(this.infoMesh, infoMeshUpdate);
  }

  // For 0-4 score only
  // OnRateSeverity_5()
  // {
  //   // this.infoMesh.severity = 5;
  //   var infoMeshUpdate: Partial<Interface_InfoMesh_Basic> = {severity: 5};
  //   Object.assign(this.infoMesh, infoMeshUpdate);
  // }

  // OnRateSeverity_6()
  // {
  //   // this.infoMesh.severity = 6;
  //   var infoMeshUpdate: Partial<Interface_InfoMesh_Basic> = {severity: 6};
  //   Object.assign(this.infoMesh, infoMeshUpdate);
  // }

  // OnRateSeverity_7()
  // {
  //   // this.infoMesh.severity = 7;
  //   var infoMeshUpdate: Partial<Interface_InfoMesh_Basic> = {severity: 7};
  //   Object.assign(this.infoMesh, infoMeshUpdate);
  // }

  // OnRateSeverity_8()
  // {
  //   // this.infoMesh.severity = 8;
  //   var infoMeshUpdate: Partial<Interface_InfoMesh_Basic> = {severity: 8};
  //   Object.assign(this.infoMesh, infoMeshUpdate);
  // }

  // OnRateSeverity_9()
  // {
  //   // this.infoMesh.severity = 9;
  //   var infoMeshUpdate: Partial<Interface_InfoMesh_Basic> = {severity: 9};
  //   Object.assign(this.infoMesh, infoMeshUpdate);
  // }

  // OnRateSeverity_10()
  // {
  //   // this.infoMesh.severity = 10;
  //   var infoMeshUpdate: Partial<Interface_InfoMesh_Basic> = {severity: 10};
  //   Object.assign(this.infoMesh, infoMeshUpdate);
  // }

  IsSeverityValid(numServity: number): boolean
  {
    // if(this.infoMesh == undefined || this.infoMesh == null || this.infoMesh.severity == undefined)
    // {
    //   return false;
    // }

    // if(this.infoMesh.severity != numServity)
    // {
    //   return false;
    // }

    // return true;
    return this.infoMesh.severity === numServity;
  }

  OnOfferSurgery_Yes()
  {
    // this.infoMesh.offer_surgery = true;
    var infoMeshUpdate: Partial<Interface_InfoMesh_Basic> = {operation: DEFINES.QUESTION_OPERATION_YES};
    Object.assign(this.infoMesh, infoMeshUpdate);
  }

  OnOfferSurgery_No()
  {
    // this.infoMesh.offer_surgery = false;
    var infoMeshUpdate: Partial<Interface_InfoMesh_Basic> = {operation: DEFINES.QUESTION_OPERATION_NO};
    Object.assign(this.infoMesh, infoMeshUpdate);
  }

  IsOfferSurgery_Yes()
  {
    if(this.infoMesh == undefined || this.infoMesh == null || this.infoMesh.operation == undefined)
    {
      return false;
    }

    if(this.infoMesh.operation==DEFINES.QUESTION_OPERATION_NO || this.infoMesh.operation == DEFINES.QUESTION_OPERATION_DEFAULT)
    {
      return false;
    }

    return true;
  }

  IsOfferSurgery_No()
  {
    if(this.infoMesh == undefined || this.infoMesh == null || this.infoMesh.operation == undefined)
    {
      return false;
    }

    if(this.infoMesh.operation==DEFINES.QUESTION_OPERATION_YES || this.infoMesh.operation == DEFINES.QUESTION_OPERATION_DEFAULT)
    {
      return false;
    }

    return true;
  }
}
