import { DICOM_MAX_SLICE_THICKNESS } from 'src/app/Configurations/Defines';
import * as DicomParser from 'dicom-parser';
import { UploadEvent, UploadFile, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop'

function eps_eq(a, b) { return Math.abs(a - b) < Number.EPSILON; };

export class CRScan {

  StudyInstanceUID = -1;
  series_no = -1;
  description = "";
  slice_dims = [];
  shape = [];

  constructor(StudyInstanceUID, series_no, description, logical_dims, shape) {
    this.StudyInstanceUID = StudyInstanceUID;
    this.series_no = series_no;
    this.description = description;
    if (!Array.isArray(logical_dims) || logical_dims.length != 2)
      throw 'illegal dims: ' + logical_dims;
    this.slice_dims = logical_dims;
    if (!Array.isArray(shape) || shape.length != 3)
      throw 'illegal shape: ' + shape;
    this.shape = shape;

    //console.log("created CRScan: ", this.toStr());
  }

  static eq(scan1, scan2) {
    var eq = true;
    eq = eq || (scan1.StudyInstanceUID == scan2.StudyInstanceUID);
    eq = eq || (scan1.series_no == scan2.series_no);
    eq = eq || (scan1.description == scan2.description);
    eq = eq || (scan1.slice_dims == scan2.slice_dims);
    for (var i = 0; i < 3; i++) {
      eq = eq || eps_eq(scan1.shape[i], scan2.shape[i]);
    }
    return eq;
  }

  toStr() {
    return JSON.stringify(this, null, 4);
  }
}

export class CRScans {

  scans = new Map();
  StudyInstanceUID = null;

  constructor() {
  }

  // Wraps FileSystemFileEntry.file, which looks like a Promise, in an _actual_ Promise.
  public static async getFile(fileEntry) {
    try {
      return await new Promise((resolve, reject) => fileEntry.file(resolve, reject));
    } catch (err) {
      console.log(err);
    }
  }

  public async addScan(fileEntry: FileSystemFileEntry /*dicom_slice*/) {
    console.log(`adding ${fileEntry.name}...`)
    var start = Date.now(); // time this
    var file = await CRScans.getFile(fileEntry) as File;

    if (!(file.type == "application/dicom" || this.isDicom(file.name))) {
      throw `{file.name} not part of a DICOM`;
    }

    // generate the buffer from the file and parse dicom
    var raw_buff = await file['arrayBuffer']();

    // try parsing the dicom file
    let buff = new Uint8Array(raw_buff);
    try
    {
      // Parse the byte array to get a DataSet object that has the parsed contents
      var dataSet = DicomParser.parseDicom(buff);

      // the DataSet object has functions to support every VR type:
      // All string types - string()
      // US - uint16()
      // SS - int16()
      // UL - uint32()
      // SL - int32()
      // FL - float()
      // FD - double()
      // DS - floatString()
      // IS - intString()

      // *** these seem to identify the study ***
      // StudyInstanceUID(x0020000d) : "1.2.840.113619.2.358.3.2198484674.196.1540701354.861"
      var StudyInstanceUID = dataSet.string('x0020000d');
      //console.log("StudyInstanceUID", StudyInstanceUID);

      // SeriesNumber(x00200011) : "2"  *** these seem to identify series of the study ***
      var series_no = dataSet.string('x00200011');
      //console.log("series_no", series_no);
      if (!series_no)
        throw "invalid file: no series id";
/*
      // SOPClassUID(x00080016) : "1.2.840.10008.5.1.4.1.1.2" [ CT Image Storage ]
      var SOPClassUID = dataSet.string('x00080016');
      console.log("SOPClassUID", SOPClassUID);

      // SOPInstanceUID(x00080018) : "1.2.840.113619.2.80.2929365402.28734.1540714082.5"
      var SOPInstanceUID = dataSet.string('x00080018');
      console.log("SOPInstanceUID", SOPInstanceUID);

      // SeriesInstanceUID(x0020000e) : "1.2.840.113619.2.80.2929365402.28734.1540714081.1.4.1"
      var SeriesInstanceUID = dataSet.string('x0020000e');
      console.log("SeriesInstanceUID", SeriesInstanceUID);

      // StudyID(x00200010) : (not present in sample scans I've looked at)
      var StudyID = dataSet.string('x00200010');
      console.log("StudyID", StudyID);

      // AcquisitionNumber(x00200012) : "1"
      var AcquisitionNumber = dataSet.string('x00200012');
      console.log("AcquisitionNumber", AcquisitionNumber);

      // InstanceNumber(x00200013) : "1"
      var instance_no = dataSet.string('x00200013');
      console.log("instance_no", instance_no);

      // ImagePositionPatient(x00200032) : "-80.099998\-59.183937\61.291859"
      // ImageOrientationPatient(x00200037) : "1.000000\0.000000\0.000000\0.000000\0.927184\-0.374607"
      // FrameOfReferenceUID(x00200052) : "2.16.840.1.113669.632.21.3083567808.322282260.216240641634610785"

      //StudyDescription(x00081030) :
      var StudyDescription = dataSet.string('x00081030');
      console.log("StudyDescription", StudyDescription);

      ///KVP(x00180060) : "100"
      var KVP = dataSet.string('x00180060');
      console.log("KVP", KVP);
*/

      // Rows(x00280010) : 512
      // Columns(x00280011) : 512
      // VR type US which is a 16 bit unsigned short field.
      var rows = dataSet.uint16('x00280010');
      var cols = dataSet.uint16('x00280011');
      var xy_dims = [cols, rows];
      //console.log("xy_dims: ", xy_dims);

      //PixelSpacing(x00280030) : "0.3613280058\0.3613280058"
      // spacing can be "0.3613\0.3613" or "0.3613,0.3613"
      var PixelSpacing = dataSet.string('x00280030');
      //console.log("PixelSpacing", PixelSpacing);
      var spacing = PixelSpacing ? PixelSpacing.split(/[,\\]/) : ["-1","-1"];
      //console.log("spacing: ",spacing);
      var slice_thickness = dataSet.string('x00180050');
      spacing.push(slice_thickness ? slice_thickness : "-1");
      var spacing_arr = spacing.map((x) => parseFloat(x));
      //console.log("spacing_arr as Numbers: ", spacing_arr);

/*
      //SpacingBetweenSlices(x00180088) : "1.250000"
      var SpacingBetweenSlices = dataSet.string('x00180088');
      console.log("SpacingBetweenSlices", SpacingBetweenSlices);

      //DataCollectionDiameter(x00180090) : "320.000000"
      var DataCollectionDiameter = dataSet.string('x00180090');
      console.log("DataCollectionDiameter", DataCollectionDiameter);

      //SamplesPerPixel(x00280002) : 1
      var SamplesPerPixel = dataSet.uint16('x00280002');
      console.log("SamplesPerPixel", SamplesPerPixel);

      //ScanOptions(x00180022) : "AXIAL MODE"  (not every scan specifies this here)
      var ScanOptions = dataSet.string('x00180022');
      console.log("ScanOptions", ScanOptions);

      //Modality(x00080060) : "CT"  (not every scan provides this here)
      var Modality = dataSet.string('x00080060');
      console.log("Modality", Modality);

      ///PatientAge(x00101010) : "003M"
      var PatientAge = dataSet.string('x00101010');
      console.log("PatientAge", PatientAge);

      //PatientPosition(x00185100) : "HFS"
      var PatientPosition = dataSet.string('x00185100');
      console.log("PatientPosition", PatientPosition);
*/

      //ImageType(x00080008) : "DERIVED\SECONDARY\REFORMATTED\AVERAGE"  (sometimes this is here)
      var ImageType = dataSet.string('x00080008');
      //console.log("ImageType", ImageType);

      // ensure only one study has been added (it can have many series)
      if (this.StudyInstanceUID === null) {
        console.log(`Adding new study, id = ${StudyInstanceUID}`);
        this.StudyInstanceUID = StudyInstanceUID;
      }
      else if (StudyInstanceUID != this.StudyInstanceUID) {
        console.log(`another study found (${StudyInstanceUID}, but ${this.StudyInstanceUID} expected)`);
        throw "multiple studies/scans found";
      }

      let scan = new CRScan(StudyInstanceUID, series_no, ImageType, xy_dims, spacing_arr);
      //console.log(scan.toStr());

      //console.log("checking if series id already exists...");
      if (!this.scans.has(series_no)) {
        console.log("adding new series to scans...");
        this.scans.set(series_no, {scan: scan, files: [fileEntry]} );
      }
      else {
        //console.log("adding this file to existing series in scan...");
        let existing_scan = this.scans.get(series_no);
        if (!CRScan.eq(existing_scan.scan, scan)) {
          var str = "this scan has different metadata than others with this series id: " + series_no;
          throw str;
        }
        existing_scan.files.push(fileEntry);
      }
    }
    catch(ex)
    {
      throw `unknown error parsing byte stream: ${ex}`;
    }
    var delta = Date.now() - start; // milliseconds elapsed since start
    console.log(`finished parsing DICOM ${fileEntry.name} in ${delta} ms`)
  }

  public bestScan() {
    if (this.scans.size == 0)
      throw "no scans";

    let keys = Array.from(this.scans.keys());
    let best = keys[0];

    // return series with the most slices that are <= max thickness
    // TODO: improve this. Priorities include dims, shape, direction (axial), type (CT)
    for (let key of keys) {
      var slices = this.scans.get(key).files.length;
      //console.log("nSlices: ", slices);
      //console.log(`scans.get(${key}): \n${this.scans.get(key).scan.toStr()}\nfiles:${JSON.stringify(this.scans.get(key).files, ["name"], 4)}`);

      let bestSlices = this.scans.get(best).files.length;
      if (slices > bestSlices && this.scans.get(key).scan.shape[2] <= DICOM_MAX_SLICE_THICKNESS)
        best = key;
    }

    var sliceThickness = this.scans.get(best).scan.shape[2];
    if (sliceThickness > DICOM_MAX_SLICE_THICKNESS)
      throw `slice thickness of series ${best} (${sliceThickness}mm) larger than maximum allowed (${DICOM_MAX_SLICE_THICKNESS}mm)`;

    return this.scans.get(best);
  }

  // If file.type not working (i.e., Windows), use this poor substitute.
  // We'll accept files with .dcm extension or no extension at all, as is sometimes the case.
  // We'll prohibit compressed files since they are likely to be mistakenly sent.
  private isDicom(fileName: string) {
    const allowedFiles = ['.dcm', ''];
    const disallowedFiles = ['.zip', '.gz', '.tar', '.tgz', '.rar', '.DS_Store'];
    const regex = /(?:\.([^.]+))?$/;
    const extension = regex.exec(fileName);
    //console.log("extension: ", extension);
    if (undefined !== extension && null !== extension) {
      for (const ext of allowedFiles) {
        if (ext === extension[0]) {
          //console.log("file type is likely a dicom");
          return true;
        }
      }
      for (const ext of disallowedFiles) {
        if (ext === extension[0]) {
          //console.log("file type is not allowed");
          return false;
        }
      }
    }
    //console.log("file type is unknown, so presuming it's a dicom");
    return true;
  }

}
