import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { Auth0Service } from 'src/app/Services/Auth0/auth0.service';
import { UserService } from 'src/app/Services/User/user.service';
import { MeshService } from 'src/app/Services/Mesh/mesh.service';



@Component({
  selector: 'app-mesh-rater',
  templateUrl: './mesh-rater.component.html',
  styleUrls: ['./mesh-rater.component.css']
})
export class MeshRaterComponent implements OnInit, AfterViewInit 
{

  constructor(public router: Router, public serviceAUTH: Auth0Service,  public serviceUser: UserService, public serviceMESH: MeshService) 
  { 
    
  }

  ngOnInit() 
  {
    // this.serviceMESH.LoadAllMeshInfo();
    // this.serviceMESH.ReloadPatchInfoFromUserService();
  }

  ngAfterViewInit()
  {
    // this.serviceMESH.LoadAllMeshInfo();
    this.serviceMESH.ReloadPatchInfoFromUserService();
  }


}
