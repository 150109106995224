import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';

@Component({
  selector: 'app-sag-survey-redirect-modal',
  templateUrl: './sag-survey-redirect-modal.component.html',
  styleUrls: ['./sag-survey-redirect-modal.component.css']
})
@Injectable()
export class SagSurveyRedirectModalComponent implements OnInit
{

  constructor(public router: Router, private modalService: NgbModal, public activeModal: NgbActiveModal) 
  { 

  }

  ngOnInit() 
  {

  }

  OnClose()
  {
    this.activeModal.close();
  }

}
