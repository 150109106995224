import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as DEFINES from "../../Configurations/Defines";
import { Interface_InfoMesh_Basic } from '../../Models/InfoMesh';
import { Auth0Service } from 'src/app/Services/Auth0/auth0.service';
import { MeshService } from 'src/app/Services/Mesh/mesh.service';
import { MeshModalPreviewComponent } from '../mesh-modal-preview/mesh-modal-preview.component';

@Component({
  selector: 'app-mesh-upload',
  templateUrl: './mesh-upload.component.html',
  styleUrls: ['./mesh-upload.component.css']
})

export class MeshUploadComponent implements OnInit 
{

  infoMeshSelected: Interface_InfoMesh_Basic = {};

  fileSelected: File = null;

  constructor(public serviceAUTH: Auth0Service, public serviceMESH: MeshService, private serviceNgModal: NgbModal)
  { 

  }

  ngOnInit() 
  {

  }

  OnFileSelected(event)
  {
    // var fileReader = new FileReader(); 

    // let objectFile = null;

    // if (event.target.files.length > 0)
    // {
    //   // updateLoad(strFile);
    //   objectFile = event.target.files[0];
    //   let strFileNameFull = objectFile.name;
    //   var listSplitted = strFileNameFull.split(".");

    //   this.infoMeshSelected.id_patient = listSplitted[0];

    //   fileReader.readAsArrayBuffer(objectFile);
    //   // fileReader.readAsText(objectFile)
      
    // }

    // fileReader.onloadend = (eventReader) => {
    //   console.log(fileReader.result);
    //   this.infoMeshSelected.data_mesh = fileReader.result as ArrayBuffer;
    // };





    let fileList: FileList = event.target.files;
    
    if (fileList.length > 0) 
    {
        
        this.fileSelected = fileList[0];
        
        let strFileNameFull = this.fileSelected.name;
        var listSplitted = strFileNameFull.split(".");

        this.infoMeshSelected.id_patient = listSplitted[0];

        var fileReader = new FileReader(); 

        fileReader.readAsArrayBuffer(this.fileSelected);
          
        fileReader.onloadend = (eventReader) => {
          console.log(fileReader.result);
          this.infoMeshSelected.data_mesh = fileReader.result as ArrayBuffer;
        };
    }



  }

  OnChangePatchID(value)
  {
    this.infoMeshSelected.id_patch = value;
    console.log(this.infoMeshSelected.type_patient);
  }

  OnChangePatientID(value)
  {
    this.infoMeshSelected.id_patient = value;
  }

  OnChangePatientType(value)
  {
    this.infoMeshSelected.type_patient = value;
  }

  OnPreviewMesh()
  {
    if(this.infoMeshSelected.data_mesh == undefined || this.infoMeshSelected.data_mesh == null)
    {
      alert("No Mesh File Selected");
    }
    else
    { 
      
      const modalActive = this.serviceNgModal.open(MeshModalPreviewComponent);// {windowClass: 'window_modal'});
      // modalActive.componentInstance.idList = this.idList;
      modalActive.componentInstance.infoMesh = this.infoMeshSelected;
      // modalActive.componentInstance.isPreview = true;

    }
  }

  OnUploadPatient()
  {
    if(this.infoMeshSelected.id_patch == undefined || this.infoMeshSelected.id_patch == null ||
       this.infoMeshSelected.id_patient == undefined || this.infoMeshSelected.id_patient == null ||
       this.infoMeshSelected.type_patient == undefined || this.infoMeshSelected.type_patient == null ||
       this.fileSelected == undefined || this.fileSelected == null)
    {
      alert("No Patch ID or Patient ID Is Provided or No Mesh File Is Selected");
    }
    else
    {
      this.serviceMESH.Send_UploadMeshV2(this.infoMeshSelected, this.fileSelected).subscribe(
        respMessageSuccess=>{
          console.log(respMessageSuccess);
  
          alert("Upload Mesh: Successed!\nMessage: " + respMessageSuccess);
        },
        respMessageError=>{
          console.log(respMessageError);
  
          alert("Upload Mesh: Failed!\nMessage: " + respMessageError);
  
          
        }
      );


















      
      // this.serviceMESH.Send_UploadMesh(this.infoMeshSelected).subscribe(
      //   respMessageSuccess=>{
      //     console.log(respMessageSuccess);
  
      //     alert("Upload Mesh: Successed!\nMessage: " + respMessageSuccess);
      //   },
      //   respMessageError=>{
      //     console.log(respMessageError);
  
      //     alert("Upload Mesh: Failed!\nMessage: " + respMessageError);
  
          
      //   }
      // );
    }


  }

}
