import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Interface_InfoMesh_Basic } from 'src/app/Models/InfoMesh';
import { Auth0Service } from 'src/app/Services/Auth0/auth0.service';
import { MeshService } from 'src/app/Services/Mesh/mesh.service';

@Component({
  selector: 'app-mesh-modal-preview',
  templateUrl: './mesh-modal-preview.component.html',
  styleUrls: ['./mesh-modal-preview.component.css']
})
export class MeshModalPreviewComponent implements OnInit {

  @Input() public infoMesh: Partial<Interface_InfoMesh_Basic>;
  
  constructor(public serviceAUTH: Auth0Service, public serviceMESH: MeshService, private modalService: NgbModal, public activeModal: NgbActiveModal)
  { 

  }

  ngOnInit() 
  {

  }

}
