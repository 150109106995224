import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';

import {
  MatButtonModule, 
  MatCheckboxModule,
  MatFormFieldModule,
  MatInputModule,
  MatRippleModule,
  MatSliderModule,
  MatSelectModule
} from '@angular/material';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { FileDropModule } from 'ngx-file-drop';
import { ChartsModule } from 'ng2-charts';

import { ROUTES } from './app.routes';

import { Auth0Service } from './Services/Auth0/auth0.service';
import { UserService } from './Services/User/user.service';
import { MeshService } from './Services/Mesh/mesh.service';
import { PredictionService } from './Services/Prediction/prediction.service';
import { FileService } from './Services/File/file.service';




import { AppComponent } from './app.component';
import { MainComponent } from './Webpages/main/main.component';
import { CallbackComponent } from './Webpages/callback/callback.component';
import { NavBarComponent } from './Widgets/nav-bar/nav-bar.component';
import { UserProfileWidgetComponent } from './Widgets/user-profile-widget/user-profile-widget.component';

import { SagSurveyRedirectModalComponent } from './Widgets/sag-survey-redirect-modal/sag-survey-redirect-modal.component';

import { MeshRaterComponent } from './Webpages/mesh-rater/mesh-rater.component';
import { MeshRateComponent } from './Widgets/mesh-rate/mesh-rate.component';
import { MeshVisualizeComponent } from './Widgets/mesh-visualize/mesh-visualize.component';
import { MeshListComponent } from './Widgets/mesh-list/mesh-list.component';
import { MeshMainComponent } from './Widgets/mesh-main/mesh-main.component';

import { MeshModalComponent } from './Widgets/mesh-modal/mesh-modal.component';
import { MeshPanelAdminComponent } from './Webpages/mesh-panel-admin/mesh-panel-admin.component';
import { MeshUploadComponent } from './Widgets/mesh-upload/mesh-upload.component';
import { MeshModalPreviewComponent } from './Widgets/mesh-modal-preview/mesh-modal-preview.component';
import { PatchAssignComponent } from './Widgets/patch-assign/patch-assign.component';
import { PatchDownloadRatingDataComponent } from './Widgets/patch-download-rating-data/patch-download-rating-data.component';
import { UserAgreementModalComponent } from './Widgets/user-agreement-modal/user-agreement-modal.component';
import { UserProfileModalComponent } from './Widgets/user-profile-modal/user-profile-modal.component';
import { RaterSurveyModalComponent } from './Widgets/rater-survey-modal/rater-survey-modal.component';
import { RaterSurveyDataDownloaderComponent } from './Widgets/rater-survey-data-downloader/rater-survey-data-downloader.component';
import { PredictionPhysicianComponent } from './Webpages/prediction-physician/prediction-physician.component';
import { PredictionUploadComponent } from './Widgets/prediction-upload/prediction-upload.component';

import { PredictionListComponent } from './Widgets/prediction-list/prediction-list.component';
import { PredictionVisualizeComponent } from './Widgets/prediction-visualize/prediction-visualize.component';
import { HelpComponent } from './Webpages/help/help.component';
import { TutorialComponent } from './Webpages/tutorial/tutorial.component';
import { UnderstandingResultsComponent } from './Webpages/understanding-results/understanding-results.component';
import { AboutComponent } from './Webpages/about/about.component';
import { TermsComponent } from './Widgets/terms/terms.component';
import { WelcomeComponent } from './Webpages/welcome/welcome.component';



const Mat_Modules = [
  MatButtonModule, 
  MatCheckboxModule,
  MatFormFieldModule,
  MatInputModule,
  MatRippleModule,
  MatSliderModule,
  MatSelectModule
];

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    CallbackComponent,
    NavBarComponent,
    UserProfileWidgetComponent,
    MeshRaterComponent,
    MeshRateComponent,
    MeshVisualizeComponent,
    MeshListComponent,
    MeshMainComponent,
    MeshModalComponent,
    MeshPanelAdminComponent,
    MeshUploadComponent,
    MeshModalPreviewComponent,
    PatchAssignComponent,
    PatchDownloadRatingDataComponent,
    UserAgreementModalComponent,
    UserProfileModalComponent,
    RaterSurveyModalComponent,
    RaterSurveyDataDownloaderComponent,
    PredictionPhysicianComponent,
    PredictionUploadComponent,
    PredictionListComponent,
    PredictionVisualizeComponent,
    HelpComponent,
    TutorialComponent,
    UnderstandingResultsComponent,
    AboutComponent,
    TermsComponent,
    WelcomeComponent,
    SagSurveyRedirectModalComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    Mat_Modules,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    HttpClientModule,
    FileDropModule,
    NgbModule.forRoot(),
    CollapseModule.forRoot(),
    ModalModule.forRoot(),
    RouterModule.forRoot(ROUTES,{
      anchorScrolling: 'enabled'
    }),
    ProgressbarModule.forRoot(),
    PaginationModule.forRoot(),
    BsDropdownModule.forRoot(),
    ChartsModule
  ],
  exports: [
    Mat_Modules
  ],
  entryComponents: [ 
    MeshModalComponent, 
    MeshModalPreviewComponent, 
    UserAgreementModalComponent, 
    UserProfileModalComponent, 
    RaterSurveyModalComponent,
    PredictionVisualizeComponent,
    PredictionUploadComponent,
    SagSurveyRedirectModalComponent
  ],
  providers: [Auth0Service, UserService, MeshService, PredictionService, FileService],
  bootstrap: [AppComponent]
})
export class AppModule { }
