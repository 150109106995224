import { Component, OnInit } from '@angular/core';
import { Auth0Service } from 'src/app/Services/Auth0/auth0.service';
import { MeshService } from 'src/app/Services/Mesh/mesh.service';

@Component({
  selector: 'app-patch-assign',
  templateUrl: './patch-assign.component.html',
  styleUrls: ['./patch-assign.component.css']
})
export class PatchAssignComponent implements OnInit 
{

  id_patch: string = "";
  
  constructor(public serviceAUTH: Auth0Service, public serviceMESH: MeshService)
  { 

  }

  ngOnInit() 
  {

  }

  OnChangePatchID(value)
  {
    this.id_patch = value;
  }

  OnAssignPatchToRaters()
  {
    this.serviceMESH.Send_AssignPatch(this.id_patch).subscribe(
      respMessageSuccess=>{
        console.log(respMessageSuccess);

        alert("Assign Patch: Successed!\nMessage: " + respMessageSuccess);
      },
      respMessageError=>{
        console.log(respMessageError);

        alert("Assign Patch: Failed!\nMessage: " + respMessageError);

        
      }
    );
  }

}
