import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as DEFINES from 'src/app/Configurations/Defines';
import { Interface_InfoUser_Basic } from 'src/app/Models/InfoUser';
import { Auth0Service } from 'src/app/Services/Auth0/auth0.service';
import { UserService } from 'src/app/Services/User/user.service';


@Component({
  selector: 'app-rater-survey-modal',
  templateUrl: './rater-survey-modal.component.html',
  styleUrls: ['./rater-survey-modal.component.css']
})
export class RaterSurveyModalComponent implements OnInit 
{

  public infoUser: Interface_InfoUser_Basic;

  constructor(public router: Router, public serviceAuth: Auth0Service, public serviceUser: UserService, private serviceNgModal: NgbModal, public activeModal: NgbActiveModal) 
  { 

  }

  ngOnInit() 
  {

  }

  IsWhichAreaOfPractice(valueCheck: string)
  {
    return this.infoUser.rater_survey_info.area_of_practice == valueCheck;
  }

  OnChooseAreaOfPractice(value: string)
  {
    this.infoUser.rater_survey_info.area_of_practice = value;
  }



  IsWhichPracticeSetting(valueCheck: string)
  {
    return this.infoUser.rater_survey_info.practice_setting == valueCheck;
  }

  OnChoosePracticeSetting(value: string)
  {
    this.infoUser.rater_survey_info.practice_setting = value;
  }

  IsWhichCurrentSurgicalStatus(valueCheck: string)
  {
    return this.infoUser.rater_survey_info.current_surgical_status == valueCheck;
  }

  OnChooseCurrentSurgicalStatus(value: string)
  {
    this.infoUser.rater_survey_info.current_surgical_status = value;
  }

  IsWhichInPracticeYears(valueCheck: string)
  {
    return this.infoUser.rater_survey_info.in_practice_years == valueCheck;
  }

  OnChooseInPracticeYears(value: string)
  {
    this.infoUser.rater_survey_info.in_practice_years = value;
  }

  IsWhichJobTitle(valueCheck: string)
  {
    return this.infoUser.rater_survey_info.job_title == valueCheck;
  }

  OnChooseJobTitle(value: string)
  {
    this.infoUser.rater_survey_info.job_title = value;
  }

  IsWhichSurgeryFrequency(valueCheck: string)
  {
    return this.infoUser.rater_survey_info.surgery_frequency == valueCheck;
  }

  OnChooseSurgeryFrequency(value: string)
  {
    this.infoUser.rater_survey_info.surgery_frequency = value;
  }

  IsWhichNumOfAnnualPatients(valueCheck: string)
  {
    return this.infoUser.rater_survey_info.num_of_annual_patients == valueCheck;
  }

  OnChooseNumOfAnnualPatients(value: string)
  {
    this.infoUser.rater_survey_info.num_of_annual_patients = value;
  }

  OnSubmitSurvey()
  {
    if(!this.CheckSurveyInfo())
    {
      alert("You still have unfinished survey questions!\nPlease finish all required questions!");
      return;
    }
    if(confirm("Are you sure you want to submit?"))
    {
      this.serviceUser.Send_SubmitRaterSurvey().subscribe(
        respMessageSuccess=>{
          console.log(respMessageSuccess);

          this.serviceUser.info.rater_survey_info.is_survey_submitted = true;
          alert("Submit Rater Survey: Successed!\n"+respMessageSuccess+"\nThank You For Your Participation!");

          this.activeModal.close();

          this.router.navigate(['/mesh-rater']);
          
        },
        respMessageError=>{
          console.log(respMessageError);

          alert("Submit Rater Survey: Failed!\n"+respMessageError+"\nPlease Check All Infor And Resubmit Again!");

        }
      );
    }
  }

  CheckSurveyInfo(): boolean
  {
    if(this.infoUser == undefined || this.infoUser == null || 
       this.infoUser.rater_survey_info == undefined || this.infoUser.rater_survey_info == null)
    {
      return false; 
    }

    if(this.infoUser.rater_survey_info.is_survey_submitted == true ||
      this.infoUser.rater_survey_info.area_of_practice == undefined || this.infoUser.rater_survey_info.area_of_practice.length <= 0 || this.infoUser.rater_survey_info.area_of_practice == DEFINES.QUESTION_SURVEY_UNANSWERED ||
      this.infoUser.rater_survey_info.practice_setting == undefined || this.infoUser.rater_survey_info.practice_setting.length <= 0 || this.infoUser.rater_survey_info.practice_setting == DEFINES.QUESTION_SURVEY_UNANSWERED ||
      this.infoUser.rater_survey_info.current_surgical_status == undefined || this.infoUser.rater_survey_info.current_surgical_status.length <= 0 || this.infoUser.rater_survey_info.current_surgical_status == DEFINES.QUESTION_SURVEY_UNANSWERED ||
      this.infoUser.rater_survey_info.in_practice_years == undefined || this.infoUser.rater_survey_info.in_practice_years.length <= 0 || this.infoUser.rater_survey_info.in_practice_years == DEFINES.QUESTION_SURVEY_UNANSWERED ||
      this.infoUser.rater_survey_info.job_title == undefined || this.infoUser.rater_survey_info.job_title.length <= 0 || this.infoUser.rater_survey_info.job_title == DEFINES.QUESTION_SURVEY_UNANSWERED ||
      this.infoUser.rater_survey_info.surgery_frequency == undefined || this.infoUser.rater_survey_info.surgery_frequency.length <= 0 || this.infoUser.rater_survey_info.surgery_frequency == DEFINES.QUESTION_SURVEY_UNANSWERED ||
      this.infoUser.rater_survey_info.num_of_annual_patients == undefined || this.infoUser.rater_survey_info.num_of_annual_patients.length <= 0 || this.infoUser.rater_survey_info.num_of_annual_patients == DEFINES.QUESTION_SURVEY_UNANSWERED || 
      this.infoUser.rater_survey_info.training_institution == undefined || this.infoUser.rater_survey_info.training_institution.length <= 0 || this.infoUser.rater_survey_info.training_institution == DEFINES.QUESTION_SURVEY_UNANSWERED ||
      this.infoUser.rater_survey_info.currently_practice_institution == undefined || this.infoUser.rater_survey_info.currently_practice_institution.length <= 0 || this.infoUser.rater_survey_info.currently_practice_institution == DEFINES.QUESTION_SURVEY_UNANSWERED)
    {  
      return false; 
    }

    return true;
  }


}
